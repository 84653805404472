import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../firebase/config';
import { collection, addDoc, query, where, getDocs, orderBy, deleteDoc, doc, getDoc, setDoc, arrayUnion } from 'firebase/firestore';
import { Form, Button, Table, Message, Header, Segment, Confirm, Icon } from 'semantic-ui-react';
import { startOfWeek, endOfWeek, format, eachWeekOfInterval } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

interface ServiceLogProps {
  moduleId: string;
  moduleStartDate: Date | Timestamp;
  moduleEndDate: Date | Timestamp;
}

interface ServiceEntry {
  id: string;
  weekStartDate: Date;
  hours: number;
  supervisor: string;
  description: string;
  submittedBy: string;
  submittedAt: Date;
}

const ServiceLog: React.FC<ServiceLogProps> = ({ moduleId, moduleStartDate, moduleEndDate }) => {
  const [user] = useAuthState(auth);
  const [entries, setEntries] = useState<ServiceEntry[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<Date>(() => {
    const startDate = moduleStartDate instanceof Timestamp ? 
      moduleStartDate.toDate() : moduleStartDate;
    return startOfWeek(startDate);
  });
  const [hours, setHours] = useState<string>('');
  const [supervisor, setSupervisor] = useState('');
  const [description, setDescription] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [wordCount, setWordCount] = useState(0);
  const [deletingEntry, setDeletingEntry] = useState<string | null>(null);

  // Get all weeks between module start and end date
  const weeks = eachWeekOfInterval({
    start: moduleStartDate instanceof Date ? moduleStartDate : moduleStartDate.toDate(),
    end: moduleEndDate instanceof Date ? moduleEndDate : moduleEndDate.toDate()
  });

  useEffect(() => {
    fetchEntries();
  }, [moduleId, user]);

  const calculateTotalHours = () => {
    return entries.reduce((total, entry) => total + entry.hours, 0);
  };

  const fetchEntries = async () => {
    if (!user) return;

    try {
      const studentDocRef = doc(db, 'modules', moduleId, 'serviceLogs', user.uid);
      const studentDoc = await getDoc(studentDocRef);
      
      if (studentDoc.exists()) {
        const submissions = studentDoc.data().submissions || [];
        const fetchedEntries = submissions.map((submission: {
          id: string;
          weekStartDate: Timestamp;
          submittedAt: Timestamp;
          hours: number;
          supervisor: string;
          description: string;
        }) => ({
          hours: submission.hours,
          supervisor: submission.supervisor,
          description: submission.description,
          id: submission.id,
          weekStartDate: submission.weekStartDate.toDate(),
          submittedAt: submission.submittedAt.toDate()
        })) as ServiceEntry[];
        
        const sortedEntries = fetchedEntries.sort((a, b) => 
          b.weekStartDate.getTime() - a.weekStartDate.getTime()
        );
        
        setEntries(sortedEntries);
      } else {
        setEntries([]);
      }
    } catch (err) {
      console.error('Error fetching entries:', err);
      setError('Failed to load service entries');
    }
  };

  const handleSubmit = async () => {
    setShowConfirm(false);
    if (!user) return;

    if (description.split(' ').length > 300) {
      setError('Description cannot exceed 300 words');
      return;
    }

    try {
      const studentDocRef = doc(db, 'modules', moduleId, 'serviceLogs', user.uid);
      const newEntry = {
        id: crypto.randomUUID(), // Using Web Crypto API directly
        weekStartDate: startOfWeek(selectedWeek),
        hours: Number(hours),
        supervisor,
        description,
        submittedAt: new Date()
      };

      await setDoc(studentDocRef, {
        submissions: arrayUnion(newEntry)
      }, { merge: true });

      setSuccess('Service entry submitted successfully!');
      setHours('');
      setSupervisor('');
      setDescription('');
      fetchEntries();
    } catch (err) {
      console.error('Error submitting entry:', err);
      setError('Failed to submit service entry');
    }
  };

  const handleDelete = async (entryId: string) => {
    if (!user) return;

    try {
      const studentDocRef = doc(db, 'modules', moduleId, 'serviceLogs', user.uid);
      const studentDoc = await getDoc(studentDocRef);
      
      if (studentDoc.exists()) {
        const submissions = studentDoc.data().submissions || [];
        const updatedSubmissions = submissions.filter(
          (submission: any) => submission.id !== entryId
        );

        await setDoc(studentDocRef, { submissions: updatedSubmissions }, { merge: true });
        setSuccess('Service entry deleted successfully!');
        fetchEntries();
      }
    } catch (err) {
      console.error('Error deleting entry:', err);
      setError('Failed to delete service entry');
    }
    setDeletingEntry(null);
  };

  return (
    <div>
      <Header as="h3">Service Log</Header>
      
      {/* Entry Form */}
      <Segment>
        <Header as="h4">Add New Service Entry</Header>
        <Message warning>
          Please note: Once submitted, service entries cannot be edited or deleted.
          Make sure all information is correct before submitting.
        </Message>
        
        <Form>
          <Form.Select
            label="Week"
            options={weeks.map(week => ({
              key: week.toISOString(),
              text: `Week of ${format(week, 'MMM d, yyyy')}`,
              value: week.toISOString()
            }))}
            value={selectedWeek.toISOString()}
            onChange={(_, { value }) => setSelectedWeek(new Date(value as string))}
          />
          <Form.Input
            label="Hours"
            type="number"
            min="0"
            step="0.5"
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            required
          />
          <Form.Input
            label="Supervisor"
            value={supervisor}
            onChange={(e) => setSupervisor(e.target.value)}
            required
          />
          <Form.TextArea
            label={`Description (${wordCount}/300 words)`}
            value={description}
            onChange={(e) => {
              const words = e.target.value.trim().split(/\s+/);
              const count = e.target.value.trim() ? words.length : 0;
              setWordCount(count);
              setDescription(e.target.value);
              if (count > 300) {
                setError('Description cannot exceed 300 words');
              } else {
                setError(null);
              }
            }}
            required
          />
          <Button
            primary
            onClick={() => setShowConfirm(true)}
            disabled={!hours || !supervisor || !description || wordCount > 300}
          >
            Submit Entry
          </Button>
        </Form>
      </Segment>

      {/* Messages */}
      {error && <Message negative>{error}</Message>}
      {success && <Message positive>{success}</Message>}

      {/* Entries Table */}
      <Segment>
        <Header as="h4">My Service Entries</Header>
        <Header as="h5">Total Hours: {calculateTotalHours()}</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Week Of</Table.HeaderCell>
              <Table.HeaderCell>Hours</Table.HeaderCell>
              <Table.HeaderCell>Supervisor</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Submitted</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {entries.map(entry => (
              <Table.Row key={entry.id}>
                <Table.Cell>{format(entry.weekStartDate, 'MMM d, yyyy')}</Table.Cell>
                <Table.Cell>{entry.hours}</Table.Cell>
                <Table.Cell>{entry.supervisor}</Table.Cell>
                <Table.Cell>{entry.description}</Table.Cell>
                <Table.Cell>{format(entry.submittedAt, 'MMM d, yyyy h:mm a')}</Table.Cell>
                <Table.Cell>
                  <Button 
                    icon 
                    color="red" 
                    size="tiny"
                    onClick={() => setDeletingEntry(entry.id)}
                  >
                    <Icon name="trash" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>

      <Confirm
        open={showConfirm}
        content="Are you sure you want to submit this service entry? It cannot be edited or deleted once submitted."
        onCancel={() => setShowConfirm(false)}
        onConfirm={handleSubmit}
      />
      <Confirm
        open={!!deletingEntry}
        content="Are you sure you want to delete this service entry?"
        onCancel={() => setDeletingEntry(null)}
        onConfirm={() => deletingEntry && handleDelete(deletingEntry)}
      />
    </div>
  );
};

export default ServiceLog;
