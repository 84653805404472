import React from 'react';
import { Segment, Header, Image, List, Label, Menu, Table } from 'semantic-ui-react';
import { formatDate } from '../../utils/dateUtils';

interface ModuleDetailsProps {
    moduleData: {
        photoUrl: string | null;
        description: string;
        startDate: Date;
        endDate: Date;
        isActive: boolean;
        viewAssignments: boolean;
        users: User[];
    };
    isAdminOrTeacher: boolean;
}

interface User {
    id: string;
    firstName: string;
    lastName: string;
    initiatedName?: string;
    role: string;
}

const ModuleDetails: React.FC<ModuleDetailsProps> = ({ moduleData, isAdminOrTeacher }) => {
    const renderUserTable = (users: User[], role: string) => (
        <Segment>
            <Header as="h4">{role}s</Header>
            <Table celled fixed>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={2}>No.</Table.HeaderCell>
                        <Table.HeaderCell width={7}>Name</Table.HeaderCell>
                        <Table.HeaderCell width={7}>Initiated Name</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {users.map((user, index) => (
                        <Table.Row key={user.id}>
                            <Table.Cell textAlign="center">{index + 1}</Table.Cell>
                            <Table.Cell>
                                {user.firstName} {user.lastName}
                            </Table.Cell>
                            <Table.Cell>
                                {user.initiatedName || '-'}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    );

    return (
        <Segment>
            <Header as="h3">Module Details</Header>
            <Image src={moduleData.photoUrl || 'default-module-image.jpg'} size="medium" />
            <List>
                <List.Item>
                    <List.Header>Description</List.Header>
                    {moduleData.description}
                </List.Item>
                <List.Item>
                    <List.Header>Start Date</List.Header>
                    {formatDate(moduleData.startDate)}
                </List.Item>
                <List.Item>
                    <List.Header>End Date</List.Header>
                    {formatDate(moduleData.endDate)}
                </List.Item>
            </List>
            {isAdminOrTeacher && (
                <>
                    {renderUserTable(moduleData.users.filter(user => user.role === 'student'), 'Student')}
                    {renderUserTable(moduleData.users.filter(user => user.role === 'grader'), 'Grader')}
                </>
            )}
        </Segment>
    );
};

export default ModuleDetails;