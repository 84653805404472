import React, { useState } from 'react';
import { Formik, Field, ErrorMessage, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox, Form as SemanticForm, Message } from 'semantic-ui-react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth, db } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';

interface SignupFormProps {
    isAdmin?: boolean;
}

interface SignupFormValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    chanting16Rounds: boolean;
    following4Principles: boolean;
    mobileNumber: string;
    initiatedName?: string;
    spiritualMaster?: string;
    role: 'student' | 'grader' | 'teacher' | 'admin' | 'coordinator';
    showInTeamPage?: boolean;
    isFirstLogin: boolean;
}

const SignupSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().min(8, 'Too Short!').required('Required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Required'),
    chanting16Rounds: Yup.boolean().when('$isAdmin', {
        is: false,
        then: (schema) => schema.oneOf([true], 'Must agree to chant 16 rounds'),
        otherwise: (schema) => schema,
    }),
    following4Principles: Yup.boolean().when('$isAdmin', {
        is: false,
        then: (schema) => schema.oneOf([true], 'Must agree to follow 4 regulative principles'),
        otherwise: (schema) => schema,
    }),
    mobileNumber: Yup.string().required('Required'),
    initiatedName: Yup.string(),
    spiritualMaster: Yup.string(),
    role: Yup.string().oneOf(['student', 'grader', 'teacher', 'admin', 'coordinator']).required('Required'),
    isAdmin: Yup.boolean(),
});

const SignupForm: React.FC<SignupFormProps> = ({ isAdmin = false }) => {
    const navigate = useNavigate();
    const [showMessage, setShowMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const initialValues: SignupFormValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        chanting16Rounds: false,
        following4Principles: false,
        mobileNumber: '',
        initiatedName: '',
        spiritualMaster: '',
        role: 'student',
        showInTeamPage: isAdmin ? true : undefined,
        isFirstLogin: true,

    };

    const handleSubmit = async (values: SignupFormValues, { resetForm }: any) => {
        setErrorMessage(null);
        setIsSubmitting(true);
        try {
            if (isAdmin) {
                // Admin creating a new user
                const functions = getFunctions();
                const createUser = httpsCallable<SignupFormValues, { success: boolean }>(functions, 'createUser');
                values.chanting16Rounds = true;
                values.following4Principles = true;
                values.showInTeamPage = true;
                values.isFirstLogin = true;
                const result = await createUser(values);
                if (result.data.success) {
                    setShowMessage(true);
                    resetForm();
                    setTimeout(() => {
                        setShowMessage(false);
                        navigate('/dashboard');
                    }, 3000);
                }
            } else {
                // Student self-signup
                const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
                const user = userCredential.user;

                await setDoc(doc(db, 'users', user.uid), {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    mobileNumber: values.mobileNumber,
                    initiatedName: values.initiatedName,
                    spiritualMaster: values.spiritualMaster,
                    chanting16Rounds: values.chanting16Rounds,
                    following4Principles: values.following4Principles,
                    role: 'student',
                    isFirstLogin: true,
                });

                navigate('/');
            }
        } catch (error) {
            console.error('Error signing up:', error);
            if (error instanceof FirebaseError) {
                switch (error.code) {
                    case 'auth/email-already-in-use':
                        setErrorMessage('This email is already in use. Please try a different email.');
                        break;
                    case 'auth/invalid-email':
                        setErrorMessage('The email address is not valid.');
                        break;
                    case 'auth/operation-not-allowed':
                        setErrorMessage('Email/password accounts are not enabled. Please contact support.');
                        break;
                    case 'auth/weak-password':
                        setErrorMessage('The password is too weak. Please choose a stronger password.');
                        break;
                    default:
                        setErrorMessage('An error occurred during signup. Please try again.');
                }
            } else {
                setErrorMessage(error instanceof Error ? error.message : 'An unknown error occurred');
            }

        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            {showMessage && (
                <Message
                    positive
                    onDismiss={() => setShowMessage(false)}
                    header="Success"
                    content="User created successfully"
                />
            )}
            {errorMessage && (
                <Message
                    negative
                    onDismiss={() => setErrorMessage(null)}
                    header="Error"
                    content={errorMessage}
                />
            )}
            <Formik
                initialValues={initialValues}
                validationSchema={SignupSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, isSubmitting, handleSubmit }) => (
                    <FormikForm className="ui form" onSubmit={handleSubmit}>
                        <SemanticForm.Group widths='equal'>
                            <SemanticForm.Field required>
                                <label htmlFor="firstName">First Name</label>
                                <Field name="firstName" placeholder="First Name" />
                                {errors.firstName && touched.firstName && (
                                    <Message negative content={errors.firstName} />
                                )}
                            </SemanticForm.Field>

                            <SemanticForm.Field required>
                                <label htmlFor="lastName">Last Name</label>
                                <Field name="lastName" placeholder="Last Name" />
                                {errors.lastName && touched.lastName && (
                                    <Message negative content={errors.lastName} />
                                )}
                            </SemanticForm.Field>
                        </SemanticForm.Group>

                        <SemanticForm.Group widths='equal'>
                            <SemanticForm.Field required>
                                <label htmlFor="email">Email</label>
                                <Field name="email" type="email" placeholder="Email" />
                                {errors.email && touched.email && (
                                    <Message negative content={errors.email} />
                                )}
                            </SemanticForm.Field>

                            <SemanticForm.Field required>
                                <label htmlFor="mobileNumber">Mobile Number</label>
                                <Field name="mobileNumber" placeholder="Mobile Number" />
                                {errors.mobileNumber && touched.mobileNumber && (
                                    <Message negative content={errors.mobileNumber} />
                                )}
                            </SemanticForm.Field>
                        </SemanticForm.Group>

                        <SemanticForm.Group widths='equal'>
                            <SemanticForm.Field required>
                                <label htmlFor="password">Password</label>
                                <Field name="password" type="password" placeholder="Password" />
                                {errors.password && touched.password && (
                                    <Message negative content={errors.password} />
                                )}
                            </SemanticForm.Field>

                            <SemanticForm.Field required>
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <Field name="confirmPassword" type="password" placeholder="Confirm Password" />
                                {errors.confirmPassword && touched.confirmPassword && (
                                    <Message negative content={errors.confirmPassword} />
                                )}
                            </SemanticForm.Field>
                        </SemanticForm.Group>

                        <SemanticForm.Group widths='equal'>
                            <SemanticForm.Field>
                                <label htmlFor="initiatedName">Initiated Name (optional)</label>
                                <Field name="initiatedName" placeholder="Initiated Name" />
                            </SemanticForm.Field>

                            <SemanticForm.Field>
                                <label htmlFor="spiritualMaster">Spiritual Master (optional)</label>
                                <Field name="spiritualMaster" placeholder="Spiritual Master" />
                            </SemanticForm.Field>
                        </SemanticForm.Group>
                        {!isAdmin && (
                            <SemanticForm.Group widths='equal'>
                                <SemanticForm.Field required>
                                    <Field name="chanting16Rounds">
                                        {({ field }: any) => (
                                            <Checkbox
                                                {...field}
                                                id="chanting16Rounds"
                                                name="chanting16Rounds"
                                                label="I am chanting or will chant 16 rounds daily"
                                                checked={field.value}
                                            />
                                        )}
                                    </Field>
                                    {errors.chanting16Rounds && touched.chanting16Rounds && (
                                        <Message negative content={errors.chanting16Rounds} />
                                    )}
                                </SemanticForm.Field>

                                <SemanticForm.Field required>
                                    <Field name="following4Principles">
                                        {({ field }: any) => (
                                            <Checkbox
                                                {...field}
                                                id="following4Principles"
                                                name="following4Principles"
                                                label="I am following or will follow the 4 regulative principles"
                                                checked={field.value}
                                            />
                                        )}
                                    </Field>
                                    {errors.following4Principles && touched.following4Principles && (
                                        <Message negative content={errors.following4Principles} />
                                    )}
                                </SemanticForm.Field>
                            </SemanticForm.Group>
                        )}

                        {isAdmin && (
                            <SemanticForm.Field required>
                                <label htmlFor="role">Role</label>
                                <Field as="select" name="role">
                                    <option value="student">Student</option>
                                    <option value="grader">Grader</option>
                                    <option value="teacher">Teacher</option>
                                    <option value="admin">Admin</option>
                                    <option value="coordinator">Coordinator</option>
                                </Field>
                                {errors.role && touched.role && (
                                    <Message negative content={errors.role} />
                                )}
                            </SemanticForm.Field>
                        )}

                        <SemanticForm.Field style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                type="submit"
                                primary
                                disabled={isSubmitting}
                                size="large"
                                style={{ width: '200px' }}
                            >
                                {isSubmitting ? 'Signing Up...' : 'Sign Up'}
                            </Button>
                        </SemanticForm.Field>

                    </FormikForm>
                )}
            </Formik>
        </>
    );
};

export default SignupForm;