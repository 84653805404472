import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/authAtom';
import WithPasswordChangeCheck from '../hoc/withPasswordChangeCheck';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const user = useRecoilValue(userState);

  if (!user.uid || !user.role) {
    return <Navigate to="/login" replace />;
  }

  return <WithPasswordChangeCheck>{children}</WithPasswordChangeCheck>;
};

export default ProtectedRoute;