import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Button, Form, Message } from 'semantic-ui-react';

const CreateAdminUser: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    const functions = getFunctions();
    const createAdminUser = httpsCallable(functions, 'createAdminUser');

    try {
      await createAdminUser({ email, password });
      setSuccess(true);
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit} error={!!error} success={success}>
      <Form.Input
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Form.Input
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button type="submit">Create Admin User</Button>
      <Message error content={error} />
      <Message success content="Admin user created successfully" />
    </Form>
  );
};

export default CreateAdminUser;