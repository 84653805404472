import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { useRecoilValue } from 'recoil';
import { userState } from '../../recoil/authAtom';
import AdminVerticalMenu from './Admin/AdminVerticalMenu';
import StudentVerticalMenu from './Student/StudentVerticalMenu';
import TeacherVerticalMenu from './Teacher/TeacherVerticalMenu';
import UsersTable from './Admin/UsersTable';
import CreateCourseBasicInfo from '../Course/CreateCourseBasicInfo';
import DashboardCourses from '../Course/DashboardCourses';
import CourseDetails from '../Course/CourseDetails';
import BulkCreateStudents from '../../Authentication/BulkCreateStudents';

const Dashboard: React.FC = () => {
  const [activeItem, setActiveItem] = useState('');
  const [editCourseId, setEditCourseId] = useState<string | null>(null);
  const [editModuleId, setEditModuleId] = useState<string | null>(null);
  const [currentCourseId, setCurrentCourseId] = useState<string | null>(null);
  const user = useRecoilValue(userState);

  useEffect(() => {
    if (user?.role === 'admin') {
      setActiveItem('users');
    } else if (user?.role === 'teacher' || user?.role === 'grader') {
      setActiveItem('courses');
    }
  }, [user]);
  
  const handleItemClick = (name: string) => {
    setActiveItem(name);
    setEditCourseId(null);
    setEditModuleId(null);
    if (name !== 'createModule') {
      setCurrentCourseId(null);
    }
  };

  const handleCourseCreated = () => {
    setActiveItem('courses');
    setEditCourseId(null);
  };

  const handleEditCourse = (courseId: string) => {
    setEditCourseId(courseId);
    setActiveItem('editCourse');
  };

  const handleModuleCreated = () => {
    setActiveItem('courseDetails');
    setEditModuleId(null);
  };

  const handleAddModule = (courseId: string) => {
    setCurrentCourseId(courseId);
    setActiveItem('createModule');
  };

  const handleEditModule = (moduleId: string, courseId: string) => {
    setEditModuleId(moduleId);
    setCurrentCourseId(courseId);
    setActiveItem('editModule');
  };

  const handleViewCourse = (courseId: string) => {
    setCurrentCourseId(courseId);
    setActiveItem('courseDetails');
  };

  const renderVerticalMenu = () => {
    switch (user?.role) {
      case 'admin':
        return <AdminVerticalMenu activeItem={activeItem} onItemClick={handleItemClick} />;
      case 'student':
        return <StudentVerticalMenu activeItem={activeItem} onItemClick={handleItemClick} />;
      case 'teacher':
      case 'grader':
        return <TeacherVerticalMenu activeItem={activeItem} onItemClick={handleItemClick} />;
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (user?.role === 'admin' && activeItem === 'users') {
      return <UsersTable />;
    }

    if (user?.role === 'admin' && activeItem === 'createStudents') {
      return <BulkCreateStudents />;
    }

    // if ((user?.role === 'admin' || user?.role === 'teacher') && activeItem === 'createCourse') {
    //   return <CreateCourseBasicInfo onCourseCreated={handleCourseCreated} />;
    // }

    // if (activeItem === 'editCourse' && editCourseId) {
    //   return <CreateCourseBasicInfo courseId={editCourseId} onCourseCreated={handleCourseCreated} />;
    // }

    // if (activeItem === 'courses') {
    //   return <DashboardCourses onEditCourse={handleEditCourse} onViewCourse={handleViewCourse} />;
    // }

/*     if ((user?.role === 'admin' || user?.role === 'teacher') && activeItem === 'createModule') {
      return <CreateModule courseId={currentCourseId || ''} onModuleCreated={handleModuleCreated} />;
    }

    if ((user?.role === 'admin' || user?.role === 'teacher') && activeItem === 'editModule' && editModuleId && currentCourseId) {
      return <CreateModule courseId={currentCourseId} moduleId={editModuleId} onModuleCreated={handleModuleCreated} />;
    } */

    // if (activeItem === 'courseDetails' && currentCourseId) {
    //   return <CourseDetails courseId={currentCourseId} onEditCourse={handleEditCourse} onEditModule={handleEditModule} />;
    // }
   
    return <div>Select an item from the menu</div>;
  };

  return (
    <Grid>
      <Grid.Column width={3}>
        {renderVerticalMenu()}
      </Grid.Column>
      <Grid.Column width={13}>
        {renderContent()}
      </Grid.Column>
    </Grid>
  );
};

export default Dashboard;