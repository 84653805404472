import React, { useState, useEffect } from 'react';
import { Card, Button, Header, Segment, Label, Icon, Loader } from 'semantic-ui-react';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db, auth } from '../../../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { formatDate } from '../../../utils/dateUtils';

interface Submission {
    id: string;
    answers: {
        assignedGraderId: string;
        assignedAt: Date;
        gradingStatus: 'pending' | 'graded';
        essay: string;
        questionIndex: number;
        submitted: boolean;
        submittedAt: Date;
    }[];
}

interface GradeOBProps {
    moduleId: string;
    testId: string;
    onBack: () => void;
    onGradeSubmission: (submissionId: string) => void;
}

const GradeOB: React.FC<GradeOBProps> = ({ moduleId, testId, onBack, onGradeSubmission }) => {
    const [user] = useAuthState(auth);
    const [submissions, setSubmissions] = useState<Submission[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchAssignedSubmissions = async () => {
            if (!user) return;

            try {
                const submissionsRef = collection(db, 'modules', moduleId, 'tests', testId, 'submissions');
                // Remove the array index from the query as Firestore doesn't support it in where clauses
                const querySnapshot = await getDocs(submissionsRef);

                // Filter the results in memory instead
                const fetchedSubmissions = querySnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...(doc.data() as Omit<Submission, 'id'>)
                    }))
                    .filter(submission => {
                        const answers = submission.answers || [];
                        return answers.length > 0 && answers[0].assignedGraderId === user.uid;
                    }) as Submission[];

                console.log('Fetched submissions:', fetchedSubmissions);
                setSubmissions(fetchedSubmissions);
            } catch (err) {
                console.error('Error fetching submissions:', err);
                setError('Failed to load submissions');
            } finally {
                setLoading(false);
            }
        };

        fetchAssignedSubmissions();
    }, [moduleId, testId, user]);

    if (loading) return <Loader active>Loading submissions...</Loader>;
    if (error) return <Segment color='red'>{error}</Segment>;

    const handleSubmissionClick = (submissionId: string) => {
        onGradeSubmission(submissionId);
    };

    return (
        <div>
            <Button
                color='grey'
                icon
                labelPosition='left'
                onClick={onBack}
                style={{ marginBottom: '1rem' }}
            >
                <Icon name='arrow left' />
                Back to Test
            </Button>

            <Header as='h2'>Assigned Submissions</Header>

            {submissions.length === 0 ? (
                <Segment placeholder>
                    <Header icon>
                        <Icon name='file outline' />
                        No pending submissions assigned to you
                    </Header>
                </Segment>
            ) : (
                <Card.Group>
                    {submissions.map((submission) => (
                        <Card fluid key={submission.id}>
                            <Card.Content>
                                <Card.Header>
                                    Submission #{submission.id.slice(-6)}
                                </Card.Header>
                                <Card.Meta>
                                    Assigned: {formatDate(submission.answers[0].assignedAt)}
                                </Card.Meta>
                                <Card.Description>
                                    <Label color={submission.answers[0].gradingStatus === 'graded' ? 'green' : 'orange'}>
                                        Status: {submission.answers[0].gradingStatus}
                                    </Label>
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <Button
                                    primary={submission.answers[0].gradingStatus === 'pending'}
                                    secondary={submission.answers[0].gradingStatus === 'graded'}
                                    fluid
                                    onClick={() => handleSubmissionClick(submission.id)}
                                >
                                    {submission.answers[0].gradingStatus === 'pending'
                                        ? 'Grade This Submission'
                                        : 'View Graded Submission'
                                    }
                                </Button>
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
            )}
        </div>
    );
};

export default GradeOB;