import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/authAtom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

interface WithPasswordChangeCheckProps {
  children: React.ReactNode;
}

const WithPasswordChangeCheck: React.FC<WithPasswordChangeCheckProps> = ({ children }) => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [needsPasswordChange, setNeedsPasswordChange] = useState(false);

  useEffect(() => {
    const checkPasswordChange = async () => {
      if (user && user.uid) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        if (userData && userData.isFirstLogin) {
          setNeedsPasswordChange(true);
        }
      }
      setIsLoading(false);
    };

    checkPasswordChange();
  }, [user, navigate]);

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading component
  }

  if (needsPasswordChange) {
    return <Navigate to="/change-password" state={{ isFirstLogin: true }} replace />;
  }

  return <>{children}</>;
};

export default WithPasswordChangeCheck;