import React, { useState, useEffect } from 'react';
import { Button, Header, Segment, Message, Confirm, List, Icon } from 'semantic-ui-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../../firebase/config';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { stripHtml } from "string-strip-html";
import parse from 'html-react-parser';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

interface StudentSubmissionOBProps {
    moduleId: string;
    testId: string;
    questionIndex: number;
    question: Question;
    onBack: () => void;
}

interface Criteria {
    description: string;
    grade: number;
    actualGrade?: number;
}

interface Question {
    text: string;
    criteria: Criteria[];
}

const StudentSubmissionOB: React.FC<StudentSubmissionOBProps> = ({ moduleId, testId, questionIndex, question, onBack }) => {
    const [user] = useAuthState(auth);
    const [essay, setEssay] = useState('');
    const [savedEssay, setSavedEssay] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [otherSavedAnswers, setOtherSavedAnswers] = useState<boolean>(false);
    const [gradingInfo, setGradingInfo] = useState<{
        criteriaGrades?: Criteria[];
        graderComments?: string;
        grade?: number;
        requiresRewrite?: boolean;
    } | null>(null);

    // useEffect(() => {
    //     // Connect to functions emulator
    //     const functions = getFunctions();
    //     connectFunctionsEmulator(functions, 'localhost', 5001);
    // }, []);

    useEffect(() => {
        let isMounted = true; // Flag to prevent state updates if component is unmounted

        const fetchEssay = async () => {
            if (user?.uid) {
                try {
                    const submissionRef = doc(db, 'modules', moduleId, 'tests', testId, 'submissions', user.uid);
                    const submissionDoc = await getDoc(submissionRef);
                    if (submissionDoc.exists()) {
                        const data = submissionDoc.data();
                        const answer = data.answers?.find((ans: any) => ans.questionIndex === questionIndex);
                        if (answer) {
                            if (isMounted) {
                                setSavedEssay(answer.essay || '');
                                setIsEditing(false);
                                setIsSubmitted(answer.submitted || false);

                                // Add this section to handle grading info
                                if (answer.gradingStatus === 'graded') {
                                    setGradingInfo({
                                        criteriaGrades: answer.criteriaGrades,
                                        graderComments: answer.graderComments,
                                        grade: answer.grade,
                                        requiresRewrite: answer.requiresRewrite
                                    });
                                }
                            }
                        } else {
                            if (isMounted) {
                                setIsEditing(true);
                            }
                        }
                        // Check for other saved answers
                        const otherAnswersExist = data.answers?.some((ans: any) => ans.questionIndex !== questionIndex);
                        if (isMounted) {
                            setOtherSavedAnswers(otherAnswersExist);
                        }
                    } else {
                        if (isMounted) {
                            setIsEditing(true);
                        }
                    }
                } catch (error) {
                    console.error("Error fetching essay:", error);
                    // Optionally set an error state here
                }
            }
        };

        fetchEssay();

        return () => {
            isMounted = false; // Cleanup flag on unmount
        };
    }, [user, moduleId, testId, questionIndex]); // Add necessary dependencies


    const handleSave = async () => {
        if (!user) return;
        setIsSaving(true);
        setError('');
        setSuccess('');
        try {
            const submissionRef = doc(db, 'modules', moduleId, 'tests', testId, 'submissions', user.uid);
            const submissionDoc = await getDoc(submissionRef);
            let answers = submissionDoc.exists() ? submissionDoc.data().answers || [] : [];

            // Update or add the essay for the specific question index
            const existingAnswerIndex = answers.findIndex((answer: any) => answer.questionIndex === questionIndex);
            if (existingAnswerIndex !== -1) {
                answers[existingAnswerIndex] = {
                    essay,
                    questionIndex,
                    lastSaved: new Date(),
                    submitted: false
                };
            } else {
                answers.push({
                    essay,
                    questionIndex,
                    lastSaved: new Date(),
                    submitted: false
                });
            }

            await setDoc(submissionRef, { answers }, { merge: true });
            setSavedEssay(essay);
            setIsEditing(false);
            setSuccess('Essay saved successfully');
        } catch (err) {
            setError('Failed to save essay. Please try again.');
        }
        setIsSaving(false);
    };

    const handleSubmit = async () => {
        if (!user) return;
        setIsSubmitting(true);
        setError('');
        setSuccess('');
        try {
            const submissionRef = doc(db, 'modules', moduleId, 'tests', testId, 'submissions', user.uid);
            const submissionDoc = await getDoc(submissionRef);
            let answers = submissionDoc.exists() ? submissionDoc.data().answers || [] : [];

            // Keep only the submitted answer for the current question index
            answers = [{
                essay,
                questionIndex,
                submitted: true,
                submittedAt: new Date(),
                gradingStatus: 'pending'
            }];

            await setDoc(submissionRef, { answers });

            setSuccess('Essay submitted successfully');
            setIsEditing(false);
            setIsSubmitted(true);
            setSavedEssay(essay);
        } catch (err) {
            setError('Failed to submit essay. Please try again.');
        }
        setIsSubmitting(false);
        setShowConfirm(false);
    };

    const handleEdit = () => {
        setEssay(savedEssay);
        setIsEditing(true);
    };

    const handleBack = () => {
        if (isEditing && essay !== savedEssay) {
            const confirmSave = window.confirm('You have unsaved changes. Do you want to save before leaving?');
            if (confirmSave) {
                handleSave().then(() => {
                    setSuccess('Essay saved successfully. You can now go back safely.');
                });
            }
        } else {
            onBack();
        }
    };

    const wordCount = (text: string) => {
        const stripped = stripHtml(text).result.trim();
        return stripped.split(/\s+/).length;
    };

    const handleEssayChange = (value: string) => {
        const words = wordCount(value);
        setEssay(value);
        if (words > 500) {
            setError('You have exceeded the 500-word limit. Please reduce your essay length to submit.');
        } else {
            setError('');
        }
    };

    return (
        <div>            
            <div style={{ display: 'flex', justifyContent: 'left', marginTop: '20px' }}>
                {/* <Button onClick={handleBack} disabled={isSubmitting}>Back to Test</Button> */}
                <Button color='grey' icon disabled={isSubmitting} labelPosition='left' onClick={handleBack} style={{ marginBottom: '1rem' }}>
                    <Icon name='arrow left' />
                    Back to Test
                </Button>
            </div>
            <Header as="h2" style={{ marginTop: '10px' }}>Essay Submission</Header>
            <Header as="h2" style={{ marginTop: '10px' }}>Question {questionIndex + 1}</Header>
            <Segment>
                <div className="rich-text-content">
                    {parse(question.text)}
                </div>
                <Header as="h5">Criteria</Header>
                <List>
                    {question.criteria.map((criterion, cIndex) => (
                        <List.Item key={cIndex}>
                            <List.Content>
                                <List.Header>{criterion.description}</List.Header>
                                <List.Description>Grade: {criterion.grade}</List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </Segment>

            {otherSavedAnswers && !isSubmitted && (
                <Message warning>
                    You have saved answers for other questions. Submitting this answer will delete those saved answers.
                </Message>
            )}

            {error && <Message negative>{error}</Message>}
            {success && <Message positive>{success}</Message>}

            {isSubmitted ? (
                <>
                    <Message info>Your essay has been submitted and can no longer be edited.</Message>
                    <Segment>
                        <div dangerouslySetInnerHTML={{ __html: savedEssay }} />
                    </Segment>
                </>
            ) : isEditing ? (
                <>
                    <Header as="h4">Word count: {wordCount(essay)} / 500</Header>
                    <ReactQuill theme="snow" value={essay} onChange={handleEssayChange} readOnly={isSubmitted} />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button primary onClick={handleSave} loading={isSaving} disabled={isSaving || isSubmitted} style={{ marginRight: '10px' }}>
                            Save Progress
                        </Button>
                        <Button color="green" onClick={() => setShowConfirm(true)} disabled={isSubmitting || isSubmitted || wordCount(essay) > 500}>
                            Submit Essay
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <Segment>
                        <div dangerouslySetInnerHTML={{ __html: savedEssay }} />
                    </Segment>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button primary onClick={handleEdit} style={{ marginRight: '10px' }}>Edit Essay</Button>
                    </div>
                </>
            )}

            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button onClick={handleBack} disabled={isSubmitting}>Back to Test</Button>
            </div> */}

            <Confirm
                open={showConfirm}
                content="Are you sure you want to submit your essay? You won't be able to edit it after submission."
                onCancel={() => setShowConfirm(false)}
                onConfirm={handleSubmit}
                confirmButton={
                    <Button positive loading={isSubmitting}>
                        OK
                    </Button>
                }
            />

            {isSubmitted && gradingInfo && (
                <Segment>
                    <Header as="h3">Grading Results</Header>

                    {gradingInfo.requiresRewrite && (
                        <Message warning>
                            <Message.Header>This submission requires a rewrite</Message.Header>
                            <p>Please reach out to the admin for next steps.</p>
                        </Message>
                    )}

                    <Header as="h4">Total Grade: {gradingInfo.grade}</Header>

                    <Header as="h4">Criteria Grades:</Header>
                    <List>
                        {gradingInfo.criteriaGrades?.map((criterion, index) => (
                            <List.Item key={index}>
                                <List.Content>
                                    <List.Header>{criterion.description}</List.Header>
                                    <List.Description>
                                        Grade: {criterion.actualGrade} / {criterion.grade}
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        ))}
                    </List>

                    <Header as="h4">Grader Comments:</Header>
                    <Segment>
                        {gradingInfo.graderComments}
                    </Segment>
                </Segment>
            )}
        </div>
    );
};

export default StudentSubmissionOB;