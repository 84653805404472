import React, { useState, useEffect } from 'react';
import { Card, Button, Header, Segment, Label } from 'semantic-ui-react';
import { collection, query, getDocs, doc, getDoc, Timestamp } from 'firebase/firestore';
import { db, auth } from '../../../firebase/config';
import { formatDate } from '../../../utils/dateUtils';
import ViewTest from './ViewTest';

interface Test {
  id: string;
  testName: string;
  lastSubmissionDate: Date;
  type: 'openBook' | 'closeBook';
  questions: any[];
  totalGrade: number;
}

interface TestStatus {
  submissionStatus: string;
  gradingStatus: string;
}

const ViewTests: React.FC<{ moduleId: string }> = ({ moduleId }) => {
  const [tests, setTests] = useState<Test[]>([]);
  const [testStatuses, setTestStatuses] = useState<Record<string, TestStatus>>({});
  const [selectedTest, setSelectedTest] = useState<string | null>(null);
  const [isStudent, setIsStudent] = useState(false);

  const fetchTestStatuses = async (fetchedTests: Test[]) => {
    const user = auth.currentUser;
    if (!user || !isStudent) return;

    const statuses: Record<string, TestStatus> = {};
    for (const test of fetchedTests) {
        const status = await fetchStudentSubmissionStatus(test.id, user.uid);
        statuses[test.id] = status;
    }
    setTestStatuses(statuses);
};

  useEffect(() => {
    const checkUserRoleAndFetchTests = async () => {
      const user = auth.currentUser;
      if (!user) return;

      // Check user role
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      const userIsStudent = userData?.role === 'student';
      setIsStudent(userIsStudent);

      // Fetch tests
      const testsCollectionRef = collection(db, 'modules', moduleId, 'tests');
      const testsQuery = query(testsCollectionRef);
      const querySnapshot = await getDocs(testsQuery);

      const fetchedTests: Test[] = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          lastSubmissionDate: data.lastSubmissionDate instanceof Timestamp 
            ? data.lastSubmissionDate.toDate() 
            : new Date(data.lastSubmissionDate)
        } as Test;
      });

      setTests(fetchedTests);

      // Only fetch submission statuses if user is a student
      if (userIsStudent) {
        await fetchTestStatuses(fetchedTests);
      }
    };

    checkUserRoleAndFetchTests();
  }, [moduleId]);

  useEffect(() => {
    if (selectedTest === null && tests.length > 0 && isStudent) {
      fetchTestStatuses(tests);
    }
  }, [selectedTest, tests, isStudent]);

  const fetchStudentSubmissionStatus = async (testId: string, studentId: string): Promise<TestStatus> => {
    // Look for specific student's submission
    const submissionDoc = await getDoc(doc(db, 'modules', moduleId, 'tests', testId, 'submissions', studentId));
    
    if (!submissionDoc.exists()) {
      return { submissionStatus: 'Not Submitted', gradingStatus: 'Pending' };
    }

    const submissionData = submissionDoc.data();
    
    // Check if any answers are submitted
    if (submissionData.answers && submissionData.answers.some((ans: any) => ans.submitted)) {
      // Check if all answers are graded
      if (submissionData.answers.every((ans: any) => ans.gradingStatus === 'graded')) {
        return { submissionStatus: 'Submitted', gradingStatus: 'Graded' };
      }
      return { submissionStatus: 'Submitted', gradingStatus: 'Pending' };
    }

    return { submissionStatus: 'Not Submitted', gradingStatus: 'Pending' };
  };

  const renderTestCard = (test: Test) => {
    const status = testStatuses[test.id] || { submissionStatus: 'Not Submitted', gradingStatus: 'Pending' };
    const gradingDeadline = new Date(new Date(test.lastSubmissionDate).getTime() + (14 * 24 * 60 * 60 * 1000));
  
    return (
      <Card fluid key={test.id}>
        <Card.Content>
          <Card.Header>{test.testName}</Card.Header>
          <Card.Meta style={{ fontSize: '1.2em', marginTop: '10px' }}>
            <div>
              <strong style={{ color: 'black' }}>Last Submission Date:</strong> 
              <span style={{ color: 'blue' }}>{formatDate(test.lastSubmissionDate)}</span>
            </div>
            <div style={{ marginTop: '5px' }}>
              <strong style={{ color: 'black' }}>Grading Deadline:</strong> 
              <span style={{ color: 'orange' }}>{formatDate(gradingDeadline)}</span>
            </div>
          </Card.Meta>
          <Card.Description>
            {isStudent && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Label color='blue' style={{ marginBottom: '5px' }}>
                  Submission Status: {status.submissionStatus}
                </Label>
                {status.submissionStatus === 'Submitted' && (
                  <Label color={status.gradingStatus === 'Graded' ? 'green' : 'orange'}>
                    Grading Status: {status.gradingStatus}
                  </Label>
                )}
              </div>
            )}
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Button primary onClick={() => setSelectedTest(test.id)}>
            View Test
          </Button>
        </Card.Content>
      </Card>
    );
  };

  const groupedTests = tests.reduce((acc, test) => {
    acc[test.type] = [...(acc[test.type] || []), test];
    return acc;
  }, {} as Record<string, Test[]>);

  const handleTestUpdate = (updatedTest: Test) => {
    setTests(prevTests => 
      prevTests.map(test => 
        test.id === updatedTest.id 
          ? {
            ...updatedTest,
            lastSubmissionDate: updatedTest.lastSubmissionDate instanceof Timestamp 
              ? updatedTest.lastSubmissionDate.toDate() 
              : new Date(updatedTest.lastSubmissionDate)
          }
          : test
      )
    );
  };

  // Add a new function to fetch tests
  const fetchTests = async () => {
    const testsCollectionRef = collection(db, 'modules', moduleId, 'tests');
    const testsQuery = query(testsCollectionRef);
    const querySnapshot = await getDocs(testsQuery);

    const fetchedTests: Test[] = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        lastSubmissionDate: data.lastSubmissionDate instanceof Timestamp 
          ? data.lastSubmissionDate.toDate() 
          : new Date(data.lastSubmissionDate)
      } as Test;
    });

    setTests(fetchedTests);

    if (isStudent) {
      await fetchTestStatuses(fetchedTests);
    }
  };

  return (
    <div>
      {selectedTest ? (
        <ViewTest
          test={tests.find(test => test.id === selectedTest) || {} as Test}
          moduleId={moduleId}
          onBack={() => {
            setSelectedTest(null);
            fetchTests(); // Refetch tests when returning
          }}
          onTestUpdate={handleTestUpdate}
        />
      ) : (
        Object.entries(groupedTests).map(([type, testsOfType]) => (
          <Segment key={type}>
            <Header as="h3">{type === 'openBook' ? 'Open Book Tests' : 'Close Book Tests'}</Header>
            {testsOfType.map(renderTestCard)}
          </Segment>
        ))
      )}
    </div>
  );
};

export default ViewTests;