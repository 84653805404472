import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const devConfig = {
  apiKey: "AIzaSyALTKCpxvDj8-jUWzlWGSGdtxoa1IUhjnY",
  authDomain: "bva-dev-6824c.firebaseapp.com",
  projectId: "bva-dev-6824c",
  storageBucket: "bva-dev-6824c.appspot.com",
  messagingSenderId: "428205338526",
  appId: "1:428205338526:web:758cfb8b6e7d0bc676b08f",
  measurementId: "G-B065JPV1MJ"
};

const prodConfig = {
  apiKey: "AIzaSyDhF_5GKmmxUZBqOtmzx5lwkSjtcscaScA",
  authDomain: "bva-prod-bd3f0.firebaseapp.com",
  projectId: "bva-prod-bd3f0",
  storageBucket: "bva-prod-bd3f0.firebasestorage.app",
  messagingSenderId: "31014309113",
  appId: "1:31014309113:web:2076ca53aa90c3ba736628",
  measurementId: "G-HZTMKZJ014"
}

const isDev = window.location.hostname === 'bva-dev-6824c.web.app' ||
  window.location.hostname === 'localhost';

const config = isDev ? devConfig : prodConfig;
//const config = prodConfig;

const app = initializeApp(config);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);


