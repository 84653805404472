import React, { useEffect, useState } from 'react';
import { Container, Header, Grid, Card, Image, Divider } from 'semantic-ui-react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase/config';
import { Link } from 'react-router-dom';
import defaultProfilePic from '../assets/profilepic.png';

interface TeamMember {
  uid: string;
  initiatedName: string;
  role: 'admin' | 'teacher' | 'grader';
  profilePicture: string;
}

const Team: React.FC = () => {
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const q = query(
        collection(db, 'users'),
        where('showInTeamPage', '==', true),
        where('role', 'in', ['admin', 'teacher', 'grader', 'coordinator'])
      );
      const querySnapshot = await getDocs(q);
      const members: TeamMember[] = querySnapshot.docs.map(doc => ({
        ...doc.data() as TeamMember,
        uid: doc.id
      }));
      setTeamMembers(members);
    };
  
    fetchTeamMembers();
  }, []);

  const renderTeamSection = (role: 'admin' | 'teacher' | 'grader' | 'coordinator') => {
    const filteredMembers = teamMembers.filter(member => member.role === role);
    return (
      <>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as='h2' textAlign='center' style={{ margin: '2em 0 1em' }}>
              {role === 'admin' ? 'Administrators' : `${role.charAt(0).toUpperCase() + role.slice(1)}s`}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {filteredMembers.map(member => (
            <Grid.Column key={member.uid} mobile={16} tablet={8} computer={4}>
              <Link to={`/team/${member.uid}`}>
                <Card fluid>
                  <div style={{
                    position: 'relative',
                    paddingTop: '100%',
                    backgroundColor: '#f9f9f9'
                  }}>
                    {!member.profilePicture ? (
                      <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '2rem'
                      }}>
                        <Image 
                          src={defaultProfilePic}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain'
                          }}
                        />
                      </div>
                    ) : (
                      <Image 
                        src={member.profilePicture} 
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                          e.currentTarget.src = defaultProfilePic;
                        }}
                      />
                    )}
                  </div>
                  <Card.Content>
                    <Card.Header textAlign='center'>{member.initiatedName}</Card.Header>
                    <Card.Meta textAlign='center'>{member.role}</Card.Meta>
                  </Card.Content>
                </Card>
              </Link>
            </Grid.Column>
          ))}
        </Grid.Row>
        <Divider section />
      </>
    );
  };

  return (
    <Container>
      
      <Grid>
        {renderTeamSection('admin')}
        {renderTeamSection('teacher')}
        {renderTeamSection('grader')}
        {renderTeamSection('coordinator')}
      </Grid>
    </Container>
  );
};

export default Team;