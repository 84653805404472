import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Header } from 'semantic-ui-react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';

interface ServiceLogEntry {
  id: string;
  weekStartDate: Date;
  hours: number;
  supervisor: string;
  description: string;
  submittedAt: Date;
}

interface ServiceLogAdminProps {
  moduleId: string;
  moduleStartDate: Date;
  moduleEndDate: Date;
  students: {
    id: string;
    firstName: string;
    lastName: string;
    initiatedName?: string;
  }[];
}

const ServiceLogAdmin: React.FC<ServiceLogAdminProps> = ({
  moduleId,
  moduleStartDate,
  moduleEndDate,
  students
}) => {
  const [selectedStudent, setSelectedStudent] = useState<string | null>(null);
  const [selectedStudentName, setSelectedStudentName] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceEntries, setServiceEntries] = useState<ServiceLogEntry[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchServiceEntries = async () => {
      if (!selectedStudent) return;
      
      setLoading(true);
      try {
        const studentDocRef = doc(db, 'modules', moduleId, 'serviceLogs', selectedStudent);
        const studentDoc = await getDoc(studentDocRef);
        
        if (studentDoc.exists()) {
          const submissions = studentDoc.data().submissions || [];
          const entries = submissions.map((submission: {
            id: string;
            weekStartDate: { toDate: () => Date };
            submittedAt: { toDate: () => Date };
            hours: number;
            supervisor: string;
            description: string;
          }) => ({
            weekStartDate: submission.weekStartDate.toDate(),
            submittedAt: submission.submittedAt.toDate(),
            hours: submission.hours,
            supervisor: submission.supervisor,
            description: submission.description,
            id: submission.id
          })) as ServiceLogEntry[];

          setServiceEntries(entries.sort((a, b) => 
            b.weekStartDate.getTime() - a.weekStartDate.getTime()
          ));
        } else {
          setServiceEntries([]);
        }
      } catch (error) {
        console.error('Error fetching service entries:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceEntries();
  }, [selectedStudent, moduleId]);

  const calculateTotalHours = () => {
    return serviceEntries.reduce((total, entry) => total + entry.hours, 0);
  };

  return (
    <div>
      <Header as="h3">Student Service Logs</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {students.map(student => (
            <Table.Row key={student.id}>
              <Table.Cell>
                {student.initiatedName || `${student.firstName} ${student.lastName}`}
              </Table.Cell>
              <Table.Cell>
                <Button
                  primary
                  onClick={() => {
                    setSelectedStudent(student.id);
                    setSelectedStudentName(student.initiatedName || `${student.firstName} ${student.lastName}`);
                    setIsModalOpen(true);
                  }}
                >
                  View Service Log
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedStudent(null);
          setServiceEntries([]);
        }}
        size="large"
      >
        <Modal.Header>Service Log - {selectedStudentName}</Modal.Header>
        <Modal.Content scrolling>
          {loading ? (
            <div>Loading...</div>
          ) : serviceEntries.length > 0 ? (
            <>
              <Header as="h4">Total Hours: {calculateTotalHours()}</Header>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Week Starting</Table.HeaderCell>
                    <Table.HeaderCell>Hours</Table.HeaderCell>
                    <Table.HeaderCell>Supervisor</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Submitted</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {serviceEntries.map(entry => (
                    <Table.Row key={entry.id}>
                      <Table.Cell>{entry.weekStartDate.toLocaleDateString()}</Table.Cell>
                      <Table.Cell>{entry.hours}</Table.Cell>
                      <Table.Cell>{entry.supervisor}</Table.Cell>
                      <Table.Cell>{entry.description}</Table.Cell>
                      <Table.Cell>{entry.submittedAt.toLocaleDateString()}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          ) : (
            <div>No service entries found for this student.</div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => {
            setIsModalOpen(false);
            setSelectedStudent(null);
            setServiceEntries([]);
          }}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ServiceLogAdmin;
