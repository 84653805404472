import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Form as SemanticForm, Message, Container, Header, Grid } from 'semantic-ui-react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase/config';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const ForgotPassword: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: { email: string }, { setSubmitting }: any) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setIsSubmitting(true);
    try {
      await sendPasswordResetEmail(auth, values.email);
      setSuccessMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setErrorMessage(error instanceof Error ? error.message : 'An unknown error occurred');
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Header as="h2" color="teal" textAlign="center">
        Reset your password
      </Header>
      {errorMessage && (
        <Message
          negative
          onDismiss={() => setErrorMessage(null)}
          header="Error"
          content={errorMessage}
        />
      )}
      {successMessage && (
        <Message
          positive
          onDismiss={() => setSuccessMessage(null)}
          header="Success"
          content={successMessage}
        />
      )}
      <Grid centered>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="ui form">
                <SemanticForm.Field required>
                  <label htmlFor="email">Email</label>
                  <Field name="email" type="email" placeholder="Email" />
                  {errors.email && touched.email && (
                    <Message negative content={errors.email} />
                  )}
                </SemanticForm.Field>

                <SemanticForm.Field style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button 
                    type="submit" 
                    primary 
                    disabled={isSubmitting} 
                    size="large"
                    style={{ width: '200px' }}
                  >
                    {isSubmitting ? 'Sending...' : 'Send Reset Email'}
                  </Button>
                </SemanticForm.Field>
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;