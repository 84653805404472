import React, { useState, useEffect } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getDoc, doc } from 'firebase/firestore';

const ProfileMenu: React.FC = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.uid) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      }
    };
    fetchUserData();
  }, [user]);

  const handleSignOut = async () => {
    await signOut(auth);
    navigate('/');
  };

  return (
    <Dropdown item icon={<Icon name="user" />}>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => navigate('/edit-profile')}>Edit Profile</Dropdown.Item>
        {userData?.role === 'admin' && (
          <Dropdown.Item onClick={() => navigate('/signup')}>Create User</Dropdown.Item>
        )}
        <Dropdown.Item onClick={handleSignOut}>Sign Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileMenu;