import React, { useState, useEffect } from 'react';
import { Form, Button, Image, TextArea,Message } from 'semantic-ui-react';

import { storage, db, auth } from '../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Resizer from 'react-image-file-resizer';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

interface ProfileData {
  bio: string;
  experience: string;
  education: string;
  profilePicture: string | null;
}

const EditProfileNonStudent: React.FC = () => {
  const [user] = useAuthState(auth);  
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState<ProfileData>({
    bio: '',
    experience: '',
    education: '',
    profilePicture: null,
  });
  const [newProfilePicture, setNewProfilePicture] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<{ type: 'positive' | 'negative', content: string } | null>(null);

  useEffect(() => {
    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  const fetchUserProfile = async () => {
    if (user?.uid) {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data() as ProfileData;
        setProfileData({
          bio: userData.bio || '',
          experience: userData.experience || '',
          education: userData.education || '',
          profilePicture: userData.profilePicture || null,
        });
      }
    }
  };

  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'file'
      );
    });

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const resizedImage = await resizeFile(file);
      setNewProfilePicture(resizedImage);
      setProfileData(prev => ({
        ...prev,
        profilePicture: URL.createObjectURL(resizedImage)
      }));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.uid) return;

    setLoading(true);
    setMessage(null);

    try {
      let profilePictureUrl = profileData.profilePicture;

      if (newProfilePicture) {
        const storageRef = ref(storage, `profile_pictures/${user.uid}`);
        await uploadBytes(storageRef, newProfilePicture);
        profilePictureUrl = await getDownloadURL(storageRef);
      }

      await updateDoc(doc(db, 'users', user.uid), {
        ...profileData,
        profilePicture: profilePictureUrl,
      });

      setMessage({ type: 'positive', content: 'Profile updated successfully!' });

      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage({ type: 'negative', content: 'Error updating profile. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {message && (
        <Message
          positive={message.type === 'positive'}
          negative={message.type === 'negative'}
          content={message.content}
        />
      )}
      <Form.Field>
        <label>Profile Picture</label>
        <input type="file" accept="image/*" onChange={handleImageChange} />
        {profileData.profilePicture && (
          <Image src={profileData.profilePicture} size="small" circular style={{ marginTop: '10px' }} />
        )}
      </Form.Field>
      <Form.Field>
        <label>Bio</label>
        <TextArea
          name="bio"
          value={profileData.bio}
          onChange={handleInputChange}
          placeholder="Tell us about yourself"
        />
      </Form.Field>
      <Form.Field>
        <label>Experience</label>
        <TextArea
          name="experience"
          value={profileData.experience}
          onChange={handleInputChange}
          placeholder="Share your relevant experience"
        />
      </Form.Field>
      <Form.Field>
        <label>Education</label>
        <TextArea
          name="education"
          value={profileData.education}
          onChange={handleInputChange}
          placeholder="List your educational background"
        />
      </Form.Field>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button type="submit" primary loading={loading}>
          Update Profile
        </Button>
      </div>
     
    </Form>
  );
};

export default EditProfileNonStudent;