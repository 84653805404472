import React, { useState, useEffect } from 'react';
import { Card, Grid, Button, Icon, Message, Image, Label } from 'semantic-ui-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../firebase/config';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

interface Module {
  moduleId: string;
  name: string;
  photoUrl: string;
  isActive: boolean;
}

const ModulesDashboard: React.FC = () => {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState<any>(null);
  const [modules, setModules] = useState<Module[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setUserData(userDoc.data());
            fetchModules(userDoc.data()?.role || 'student', user.uid);
          }
        }
      };
    fetchUserData();
  }, [user]);

  const fetchModules = async (role: string, uid: string) => {
    setLoading(true);
    setError(null);
    try {
      let modulesQuery;
      if (role === 'admin' || role === 'teacher' || role === 'coordinator') {
        modulesQuery = query(collection(db, 'modules'));
      } else {
        modulesQuery = query(collection(db, 'modules'), where('users', 'array-contains', uid));
      }
      const modulesSnapshot = await getDocs(modulesQuery);
      const modulesList = modulesSnapshot.docs.map(doc => ({
        moduleId: doc.id,
        name: doc.data().name,
        photoUrl: doc.data().photoUrl || 'default-module-image.jpg',
        isActive: doc.data().isActive || false,
      } as Module));
      setModules(modulesList);
    } catch (err) {
      console.error('Error fetching modules:', err);
      setError('Failed to load modules. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoToModule = (moduleId: string) => {
    navigate(`/modules/${moduleId}`);
  };

  const handleCreateModule = () => {
    navigate('/modules/create-module');
  };

  if (!user) {
    return <div>Please log in to view modules.</div>;
  }

  if (loading) return <div>Loading modules...</div>;
  if (error) return <Message negative>{error}</Message>;

  return (
    <div>
      <h2>Modules</h2>
      {(userData?.role === 'admin' || userData?.role === 'teacher') && (
        <Button primary onClick={handleCreateModule} style={{ marginBottom: '1rem' }}>
          <Icon name="plus" /> Create New Module
        </Button>
      )}
      {modules.length === 0 ? (
        <Message info>
          <Message.Header>No modules found</Message.Header>
          <p>
            {userData?.role === 'student' || userData?.role === 'grader'
              ? "You are not part of any modules. If you have completed registration, please contact the admin."
              : "There are no modules created yet."}
          </p>
        </Message>
      ) : (
        <Grid columns={3} doubling stackable>
          {modules.map(module => (
            <Grid.Column key={module.moduleId}>
              <Card>
                <Image src={module.photoUrl} wrapped ui={false} />
                <Card.Content>
                  
                  {module.isActive && (
                    <Label color="green" ribbon="right">
                      Active
                    </Label>
                  )}
                  {!module.isActive && (
                    <Label color="red" ribbon="right">
                      Inactive
                    </Label>
                  )}
                  <Card.Header>{module.name}</Card.Header>
                </Card.Content>
                <Card.Content extra>
                  <Button primary fluid onClick={() => handleGoToModule(module.moduleId)} disabled={!module.isActive}>
                  {module.isActive ? 'Go to Module' : 'Inactive'}
                  </Button>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default ModulesDashboard;