import React from 'react';
import { Container } from 'semantic-ui-react';
import Navigation from './Navigation';
import PageTitle from './PageTitle';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Container>
      <Navigation />
      <PageTitle />
      {children}
    </Container>
  );
};

export default Layout;