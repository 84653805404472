import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Input } from 'semantic-ui-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, storage, db } from '../../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { formatDate } from '../../utils/dateUtils';

interface ModuleData {
  moduleId: string;
  name: string;
  description: string;
  startDate: Date | null;
  endDate: Date | null;
  isActive: boolean;
  viewAssignments: boolean;
  photoUrl: string | null;
  moduleInfoUrl: string | null;
  questionBankUrl: string | null;
  updatedBy: string;
  updatedDate: Date;
}

const EditModule: React.FC<{ moduleData: ModuleData; onUpdateSuccess: () => void }> = ({ moduleData, onUpdateSuccess }) => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ModuleData>(moduleData);
  const [photo, setPhoto] = useState<File | null>(null);
  const [moduleInfo, setModuleInfo] = useState<File | null>(null);
  const [questionBank, setQuestionBank] = useState<File | null>(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'file'
      );
    });

  const handlePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const resizedImage = await resizeFile(file);
      setPhoto(resizedImage);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files && e.target.files.length > 0) {
      setter(e.target.files[0]);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    data?: any
  ) => {
    const { name, value, type } = data || e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? data.checked : value
    }));
  };

  const handleDateChange = (date: Date | null, field: keyof ModuleData) => {
    setFormData(prev => ({ ...prev, [field]: date }));
  };

  const uploadFile = async (file: File, path: string) => {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    setSubmitting(true);

    if (!user) {
      setError('You must be logged in to edit a module');
      setSubmitting(false);
      return;
    }

    try {
      let photoUrl = formData.photoUrl;
      let moduleInfoUrl = formData.moduleInfoUrl;
      let questionBankUrl = formData.questionBankUrl;

      if (photo) {
        photoUrl = await uploadFile(photo, `modules/${formData.name}/${photo.name}`);
      }
      if (moduleInfo) {
        moduleInfoUrl = await uploadFile(moduleInfo, `modules/${formData.name}/moduleInfo/${moduleInfo.name}`);
      }
      if (questionBank) {
        questionBankUrl = await uploadFile(questionBank, `modules/${formData.name}/questionBank/${questionBank.name}`);
      }

      const userName = user.displayName || `${user.email}`;
      const moduleDataToUpdate = {
        ...formData,
        photoUrl,
        moduleInfoUrl,
        questionBankUrl,
        updatedBy: userName,
        updatedDate: new Date(),
      };

      await updateDoc(doc(db, 'modules', formData.moduleId), moduleDataToUpdate);

      setSuccess(true);
      setTimeout(() => {
        onUpdateSuccess();
      }, 3000);
    } catch (err) {
      console.error(err);
      setError('Failed to update module. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h2>Edit Module</h2>
      {success && (
        <Message
          success
          content="Module updated successfully!"
          style={{ marginBottom: '20px' }}
        />
      )}
      <Form onSubmit={handleSubmit} error={!!error} success={success}>
        <Form.Input
          label="Module Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
          input={{ type: 'text' }}
        />
        <Form.TextArea
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          required
          rows={4}
        />
        <Form.Field>
        <label>Module Duration</label>
        <DatePicker
          selected={formData.startDate ? new Date(formatDate(formData.startDate)) : undefined}
          onChange={(date: Date | null) => handleDateChange(date, 'startDate')}
          selectsStart
          startDate={formData.startDate ? new Date(formatDate(formData.startDate)) : undefined}
          endDate={formData.endDate ? new Date(formatDate(formData.endDate)) : undefined}
          dateFormat="dd/MM/yyyy"
          placeholderText="Start Date"
        />
        <DatePicker
          selected={formData.endDate ? new Date(formatDate(formData.endDate)) : undefined}
          onChange={(date: Date | null) => handleDateChange(date, 'endDate')}
          selectsEnd
          startDate={formData.startDate ? new Date(formatDate(formData.startDate)) : undefined}
          endDate={formData.endDate ? new Date(formatDate(formData.endDate)) : undefined}
          dateFormat="dd/MM/yyyy"
          placeholderText="End Date"
        />
      </Form.Field>
        <Form.Checkbox
          label="Is Active"
          name="isActive"
          checked={formData.isActive}
          onChange={(e, data) => handleInputChange(e as React.ChangeEvent<HTMLInputElement>, data)}
          toggle
        />
        <Form.Checkbox
          label="View Assignments"
          name="viewAssignments"
          checked={formData.viewAssignments}
          onChange={(e, data) => handleInputChange(e as React.ChangeEvent<HTMLInputElement>, data)}
          toggle
        />
         <Form.Field>
        <label>Module Photo</label>
        {formData.photoUrl && (
          <div>
            <img src={formData.photoUrl} alt="Module Photo" style={{ maxWidth: '100px', maxHeight: '100px' }} />
          </div>
        )}
        <Input
          type="file"
          accept="image/*"
          onChange={handlePhotoChange}
        />
      </Form.Field>
      
      <Form.Field>
        <label>Module Info Document (Optional)</label>
        {formData.moduleInfoUrl && (
          <Button
            as="a"
            href={formData.moduleInfoUrl}
            target="_blank"
            rel="noopener noreferrer"
            icon="file"
            labelPosition="left"
            content="View Current Module Info"
            primary
          />
        )}
        <Input
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={(e) => handleFileChange(e, setModuleInfo)}
        />
      </Form.Field>
      
      <Form.Field>
        <label>Question Bank Document (Optional)</label>
        {formData.questionBankUrl && (
          <Button
            as="a"
            href={formData.questionBankUrl}
            target="_blank"
            rel="noopener noreferrer"
            icon="file"
            labelPosition="left"
            content="View Current Question Bank"
            primary
          />
        )}
        <Input
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={(e) => handleFileChange(e, setQuestionBank)}
        />
      </Form.Field>
      <div style={{ textAlign: 'center' }}>
        <Button type="submit" primary disabled={submitting}>
          {submitting ? 'Updating...' : 'Update Module'}
        </Button>
      </div>
        <Message error content={error} />       
      </Form>
    </div>
  );
};

export default EditModule;