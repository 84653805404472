import React from 'react';
import { Menu } from 'semantic-ui-react';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../recoil/authAtom'; // Adjust the import path as needed

interface TeacherVerticalMenuProps {
  activeItem: string;
  onItemClick: (name: string) => void;
}

const TeacherVerticalMenu: React.FC<TeacherVerticalMenuProps> = ({ activeItem, onItemClick }) => {
  const user = useRecoilValue(userState);

  return (
    <Menu vertical>
      <Menu.Item
        name='courses'
        active={activeItem === 'courses'}
        onClick={() => onItemClick('courses')}
      >
        Courses
      </Menu.Item>
      {user?.role === 'teacher' && (
        <>
          <Menu.Item
            name='createCourse'
            active={activeItem === 'createCourse'}
            onClick={() => onItemClick('createCourse')}
          >
            Create Course
          </Menu.Item>
          <Menu.Item
            name='createModule'
            active={activeItem === 'createModule'}
            onClick={() => onItemClick('createModule')}
          >
            Create Module
          </Menu.Item>
        </>
      )}
      {/* Add more teacher/grader-specific menu items here */}
    </Menu>
  );
};

export default TeacherVerticalMenu;