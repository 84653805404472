import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, Image, Header, Segment, Button, Placeholder } from 'semantic-ui-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import defaultProfilePic from '../assets/profilepic.png';

interface TeamMemberData {
  initiatedName: string;
  role: string;
  profilePicture: string;
  bio: string;
  experience: string;
  education: string;
}

const TeamMember: React.FC = () => {
  const { uid } = useParams<{ uid: string }>();
  const [memberData, setMemberData] = useState<TeamMemberData | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMemberData = async () => {
      if (!uid) return;
      try {
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setMemberData(docSnap.data() as TeamMemberData);
        }
      } catch (error) {
        console.error("Error fetching team member data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMemberData();
  }, [uid]);

  const renderContent = () => (
    <Grid stackable>
      <Grid.Row>
      <Grid.Column width={6}>
        <div style={{
          width: '250px',
          margin: 'auto',
        }}>
          <div style={{
            width: '100%',
            height: '250px',
            overflow: 'hidden',
            borderRadius: '10px 10px 0 0', // Rounded corners only at the top
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
          }}>
            <Image 
              src={memberData?.profilePicture || defaultProfilePic} 
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = defaultProfilePic;
              }}
            />
          </div>
          <Segment 
            textAlign='center' 
            color='teal' 
            style={{ 
              marginTop: '0',
              borderRadius: '0 0 10px 10px', // Rounded corners only at the bottom
              width: '100%'
            }}
          >
            <Header as='h3'>{memberData?.role.toUpperCase()}</Header>
          </Segment>
        </div>
      </Grid.Column>
        <Grid.Column width={10}>
          <Header as='h1'>{memberData?.initiatedName}</Header>
          <Segment>
            <Header as='h3'>Bio</Header>
            <p>{memberData?.bio}</p>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Segment>
            <Header as='h3'>My Experience</Header>
            <p>{memberData?.experience}</p>
          </Segment>
        </Grid.Column>
        <Grid.Column width={8}>
          <Segment>
            <Header as='h3'>My Education</Header>
            <p>{memberData?.education}</p>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} textAlign='center'>
          <Button 
            primary
            onClick={() => navigate('/team')}
            style={{ marginTop: '2em' }}
          >
            Back to Team
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  const renderPlaceholder = () => (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={6}>
          <Placeholder style={{ height: 250, width: 250, margin: 'auto' }}>
            <Placeholder.Image circular />
          </Placeholder>
          <Segment textAlign='center' color='teal'>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line length='medium' />
              </Placeholder.Header>
            </Placeholder>
          </Segment>
        </Grid.Column>
        <Grid.Column width={10}>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line length='very long' />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line length='full' />
              <Placeholder.Line length='very long' />
              <Placeholder.Line length='long' />
            </Placeholder.Paragraph>
          </Placeholder>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Segment>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line length='medium' />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line length='full' />
                <Placeholder.Line length='very long' />
                <Placeholder.Line length='long' />
              </Placeholder.Paragraph>
            </Placeholder>
          </Segment>
        </Grid.Column>
        <Grid.Column width={8}>
          <Segment>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line length='medium' />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line length='full' />
                <Placeholder.Line length='very long' />
                <Placeholder.Line length='long' />
              </Placeholder.Paragraph>
            </Placeholder>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  return (
    <Container style={{ marginTop: '2em', marginBottom: '2em' }}>
      {loading ? renderPlaceholder() : renderContent()}
    </Container>
  );
};

export default TeamMember;