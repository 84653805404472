import React, { useState, useEffect } from 'react';
import { Form, Button, Image, Input, Message, Grid } from 'semantic-ui-react';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/authAtom';
import { storage, db } from '../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Resizer from 'react-image-file-resizer';
import { useNavigate } from 'react-router-dom';

interface StudentProfileData {
  initiatedName: string;
  spiritualMaster: string;
  mobileNumber: string;
  profilePicture: string | null;
  recommendationLetterUrl: string | null;
  registrationFormUrl: string | null;
}

const EditProfileStudent: React.FC = () => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState<StudentProfileData>({
    initiatedName: '',
    spiritualMaster: '',
    mobileNumber: '',
    profilePicture: null,
    recommendationLetterUrl: null,
    registrationFormUrl: null,
  });
  const [newProfilePicture, setNewProfilePicture] = useState<File | null>(null);
  const [recommendationLetter, setRecommendationLetter] = useState<File | null>(null);
  const [registrationForm, setRegistrationForm] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<{ type: 'positive' | 'negative', content: string } | null>(null);

  useEffect(() => {
    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  const fetchUserProfile = async () => {
    if (user?.uid) {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data() as StudentProfileData;
        setProfileData({
          initiatedName: userData.initiatedName || '',
          spiritualMaster: userData.spiritualMaster || '',
          mobileNumber: userData.mobileNumber || '',
          profilePicture: userData.profilePicture || null,
          recommendationLetterUrl: userData.recommendationLetterUrl || null,
          registrationFormUrl: userData.registrationFormUrl || null,
        });
      }
    }
  };

  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'file'
      );
    });

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const resizedImage = await resizeFile(file);
      setNewProfilePicture(resizedImage);
      setProfileData(prev => ({
        ...prev,
        profilePicture: URL.createObjectURL(resizedImage)
      }));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const uploadFile = async (file: File, path: string) => {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.uid) return;

    setLoading(true);
    setMessage(null);

    try {
      let profilePictureUrl = profileData.profilePicture;

      if (newProfilePicture) {
        profilePictureUrl = await uploadFile(newProfilePicture, `profile_pictures/${user.uid}`);
      }

      await updateDoc(doc(db, 'users', user.uid), {
        initiatedName: profileData.initiatedName,
        spiritualMaster: profileData.spiritualMaster,
        mobileNumber: profileData.mobileNumber,
        profilePicture: profilePictureUrl,
      });

      setMessage({ type: 'positive', content: 'Profile updated successfully!' });

      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage({ type: 'negative', content: 'Error updating profile. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentUpload = async (documentType: 'recommendationLetter' | 'registrationForm') => {
    if (!user?.uid) return;

    setLoading(true);
    setMessage(null);

    try {
      const file = documentType === 'recommendationLetter' ? recommendationLetter : registrationForm;
      if (!file) {
        setMessage({ type: 'negative', content: 'Please select a file to upload.' });
        return;
      }

      const url = await uploadFile(file, `${documentType}s/${user.uid}`);

      await updateDoc(doc(db, 'users', user.uid), {
        [`${documentType}Url`]: url,
      });

      setMessage({ type: 'positive', content: `${documentType === 'recommendationLetter' ? 'Recommendation Letter' : 'Registration Form'} uploaded successfully!` });

      // Reset the file input
      if (documentType === 'recommendationLetter') {
        setRecommendationLetter(null);
      } else {
        setRegistrationForm(null);
      }

      // Refresh the profile data
      fetchUserProfile();
    } catch (error) {
      console.error(`Error uploading ${documentType}:`, error);
      setMessage({ type: 'negative', content: `Error uploading ${documentType === 'recommendationLetter' ? 'Recommendation Letter' : 'Registration Form'}. Please try again.` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {message && (
        <Message
          positive={message.type === 'positive'}
          negative={message.type === 'negative'}
          content={message.content}
        />
      )}
      <Grid>
        <Grid.Column width={10}>
          <Form.Field>
            <label>Profile Picture</label>
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {profileData.profilePicture && (
              <Image src={profileData.profilePicture} size="small" circular style={{ marginTop: '10px' }} />
            )}
          </Form.Field>
          <Form.Field>
            <label>Initiation Name</label>
            <Input
              name="initiatedName"
              value={profileData.initiatedName}
              onChange={handleInputChange}
              placeholder="Your Initiated Name"
            />
          </Form.Field>
          <Form.Field>
            <label>Spiritual Master</label>
            <Input
              name="spiritualMaster"
              value={profileData.spiritualMaster}
              onChange={handleInputChange}
              placeholder="Your spiritual master's name"
            />
          </Form.Field>
          <Form.Field>
            <label>Mobile Number</label>
            <Input
              name="mobileNumber"
              value={profileData.mobileNumber}
              onChange={handleInputChange}
              placeholder="Your mobile number"
            />
          </Form.Field>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button type="submit" primary loading={loading}>
              Update Profile
            </Button>
          </div>
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Field>
            <label>Recommendation Letter</label>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={(e) => handleFileChange(e, setRecommendationLetter)}
            />
            {profileData.recommendationLetterUrl ? (
              <Message positive>
                <Message.Header>Recommendation Letter Uploaded</Message.Header>
                <p>You have successfully uploaded your recommendation letter.</p>
              </Message>
            ) : (
              <Button
                onClick={() => handleDocumentUpload('recommendationLetter')}
                disabled={!recommendationLetter || loading}
              >
                Upload Recommendation Letter
              </Button>
            )}
          </Form.Field>
          <Form.Field>
            <label>Registration Form</label>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={(e) => handleFileChange(e, setRegistrationForm)}
            />
            {profileData.registrationFormUrl ? (
              <Message positive>
                <Message.Header>Registration Form Uploaded</Message.Header>
                <p>You have successfully uploaded your registration form.</p>
              </Message>
            ) : (
              <Button
                onClick={() => handleDocumentUpload('registrationForm')}
                disabled={!registrationForm || loading}
              >
                Upload Registration Form
              </Button>
            )}
          </Form.Field>
        </Grid.Column>
      </Grid>
    </Form>
  );
};

export default EditProfileStudent;