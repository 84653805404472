import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import SignupForm from '../Authentication/SignupForm';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/authAtom';

const SignUp: React.FC = () => {
  const user = useRecoilValue(userState);

  return (
    <Container>
      <Header as="h2" color="teal" textAlign="center">
        {user.role === 'admin' ? 'Create a new user' : 'Sign up for a new account'}
      </Header>
      <SignupForm isAdmin={user.role === 'admin'} />
    </Container>
  );
};

export default SignUp;