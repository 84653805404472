import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Header, Message, Menu, Button, Icon } from 'semantic-ui-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../firebase/config';
import { collection, doc, DocumentData, DocumentSnapshot, getDoc, getDocs, query, where } from 'firebase/firestore';
import ModuleDetails from './ModuleDetails';
import EditModule from './EditModule';
import AddUsers from './AddUsers';
import CreateTest from './Test/CreateTest';
import ViewTests from './Test/ViewTests';
import ModuleDocuments from './ModuleDocuments';
import ViewStatus from './ViewStatus';
import ServiceLog from './ServiceLog';
import ServiceLogAdmin from './ServiceLogAdmin';

interface ModuleData {
  moduleId: string;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
  viewAssignments: boolean;
  photoUrl: string | null;
  moduleInfoUrl: string | null;
  questionBankUrl: string | null;
  updatedBy: string; // Add this line
  updatedDate: Date;  // Add this line
  users: User[];
}

interface User {
    id: string;
    firstName: string;
    lastName: string;
    initiatedName?: string;
    role: string;
  }

interface ServiceEntry {
  id: string;
  weekStartDate: Date;
  hours: number;
  supervisor: string;
  description: string;
  submittedBy: string;
  submittedAt: Date;
}

const ModuleHome: React.FC = () => {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [user, loading] = useAuthState(auth);
  const [moduleData, setModuleData] = useState<ModuleData | null>(null);
  const [userData, setUserData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [activeMenuItem, setActiveMenuItem] = useState<string>('details');
  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!moduleId || !user) return;

      try {
        const [moduleDoc, userDoc] = await Promise.all([
          getDoc(doc(db, 'modules', moduleId)),
          getDoc(doc(db, 'users', user.uid))
        ]);

        if (moduleDoc.exists()) {
          const moduleData = moduleDoc.data() as ModuleData;
          setModuleData(moduleData);

          // Fetch user details for each user ID in the module
          const userIds = moduleData.users || [];
          const userPromises = userIds.map(userId => {
            if (typeof userId === 'string') {
              return getDoc(doc(db, 'users', userId));
            }
            console.error('Invalid userId:', userId);
            return Promise.resolve(null);
          });
          const userDocs = await Promise.all(userPromises);

          const fetchedUsers: User[] = userDocs
            .filter((doc): doc is DocumentSnapshot<DocumentData> => doc !== null && doc.exists())
            .map(doc => {
              const userData = doc.data() as User;
              return {
                ...userData,
                id: doc.id
              };
            });

          setUsers(fetchedUsers);
        } else {
          setError('Module not found');
        }

        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again.');
      }
    };

    fetchData();
  }, [moduleId, user]);

  const handleModuleUpdateSuccess = async () => {
    setActiveMenuItem('details');
    // Refresh the module data
    if (moduleId && user) {
      try {
        const moduleDoc = await getDoc(doc(db, 'modules', moduleId));
        if (moduleDoc.exists()) {
          setModuleData(moduleDoc.data() as ModuleData);
        }
      } catch (err) {
        console.error('Error refreshing module data:', err);
        setError('Failed to refresh module data. Please try again.');
      }
    }
};

const handleTestCreated = () => {
    setActiveMenuItem('viewAssignments');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <Message negative>{error}</Message>;
  if (!moduleData) return <div>Module not found</div>;

  const canEdit = userData?.role === 'admin' || userData?.role === 'teacher';
  const canAddUsers = userData?.role === 'admin' || userData?.role === 'teacher';
  const canCreateTest = userData?.role === 'admin' || userData?.role === 'teacher' || userData?.role === 'grader';
  const canViewStatus = userData?.role === 'admin' || userData?.role === 'teacher' || userData?.role === 'coordinator';

  const renderContent = () => {
    switch (activeMenuItem) {
      case 'details':
        return <ModuleDetails moduleData={{...moduleData, users}} isAdminOrTeacher={canEdit} />;
      case 'editModule':
        return <EditModule moduleData={moduleData} onUpdateSuccess={handleModuleUpdateSuccess} />;
      case 'addUsers':
        return <AddUsers moduleId={moduleId || ''} />;
      case 'createTest':
        return <CreateTest moduleId={moduleId || ''} onTestCreated={handleTestCreated} />;
      case 'viewAssignments':
        return <ViewTests moduleId={moduleId || ''} />;
      case 'viewStatus':
        return <ViewStatus moduleId={moduleId || ''} />;
        case 'documents':
        return <ModuleDocuments moduleData={moduleData} />;
      case 'serviceLog':
        return userData?.role === 'student' ? (
          <ServiceLog 
            moduleId={moduleId || ''} 
            moduleStartDate={moduleData.startDate} 
            moduleEndDate={moduleData.endDate} 
          />
        ) : (
          <ServiceLogAdmin 
            moduleId={moduleId || ''} 
            moduleStartDate={moduleData.startDate} 
            moduleEndDate={moduleData.endDate}
            students={users.filter(user => user.role === 'student')}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Header as="h2">{moduleData.name}</Header>
      <Menu pointing secondary>
        <Menu.Item
          name='details'
          active={activeMenuItem === 'details'}
          onClick={() => setActiveMenuItem('details')}
        >
          Module Details
        </Menu.Item>
        <Menu.Item
          name='documents'
          active={activeMenuItem === 'documents'}
          onClick={() => setActiveMenuItem('documents')}
        >
          Module Documents
        </Menu.Item>
        {(canEdit || moduleData.viewAssignments) && (
          <Menu.Item
            name='viewAssignments'
            active={activeMenuItem === 'viewAssignments'}
            onClick={() => setActiveMenuItem('viewAssignments')}
          >
            View Tests
          </Menu.Item>
        )}
        {canEdit && (
          <>
            <Menu.Item
              name='editModule'
              active={activeMenuItem === 'editModule'}
              onClick={() => setActiveMenuItem('editModule')}
            >
              Edit Module
            </Menu.Item>
            <Menu.Item
              name='addUsers'
              active={activeMenuItem === 'addUsers'}
              onClick={() => setActiveMenuItem('addUsers')}
            >
              Add Users
            </Menu.Item>
          </>
        )}
        {canCreateTest && (
          <Menu.Item
            name='createTest'
            active={activeMenuItem === 'createTest'}
            onClick={() => setActiveMenuItem('createTest')}
          >
            Create Test
          </Menu.Item>
        )}
        {canViewStatus && (
          <Menu.Item
            name='viewStatus'
            active={activeMenuItem === 'viewStatus'}
            onClick={() => setActiveMenuItem('viewStatus')}
          >
            View Status
          </Menu.Item>
        )}
        {(userData?.role === 'student' || 
          userData?.role === 'admin' || 
          userData?.role === 'teacher' || 
          userData?.role === 'coordinator') && (
          <Menu.Item
            name='serviceLog'
            active={activeMenuItem === 'serviceLog'}
            onClick={() => setActiveMenuItem('serviceLog')}
          >
            Service Log
          </Menu.Item>
        )}
      </Menu>
      <Button color='grey' icon labelPosition='left' onClick={() => navigate('/modules')} style={{ marginBottom: '1rem' }}>
        <Icon name='arrow left' />
        Back to All Modules
      </Button>
      {renderContent()}
    </div>
  );
};

export default ModuleHome;