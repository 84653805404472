import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, Message, Container, Header } from 'semantic-ui-react';
import { updatePassword } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase/config';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/authAtom';

const ChangePassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useRecoilValue(userState);
  const [isFirstLogin, setIsFirstLogin] = useState(location.state?.isFirstLogin);

  useEffect(() => {
    const checkFirstLogin = async () => {
      if (user && user.uid) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        if (userData) {
          setIsFirstLogin(userData.isFirstLogin);
        }
      }
    };

    if (!isFirstLogin) {
      checkFirstLogin();
    }
  }, [user, isFirstLogin]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const user = auth.currentUser;
      if (user) {
        await updatePassword(user, newPassword);
        
        if (isFirstLogin) {
          await updateDoc(doc(db, 'users', user.uid), {
            isFirstLogin: false
          });
        }
        
        setSuccess(true);
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        setError('No user is currently signed in');
      }
    } catch (error) {
      setError('Failed to update password. Please try again.');
    }
  };

  return (
    <Container text style={{ marginTop: '2em' }}>
      <Header as="h2" textAlign="center">
        {isFirstLogin ? 'Set New Password' : 'Change Password'}
      </Header>
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <label>New Password</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>Confirm New Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </Form.Field>
        <Button type="submit" fluid primary>Change Password</Button>
      </Form>
      {error && <Message negative>{error}</Message>}
      {success && <Message positive>Password changed successfully! Redirecting...</Message>}
    </Container>
  );
};

export default ChangePassword;