import React from 'react';
import { Header, Button, Message, Grid } from 'semantic-ui-react';

interface ModuleDocumentsProps {
  moduleData: {
    moduleInfoUrl: string | null;
    questionBankUrl: string | null;
  };
}

const ModuleDocuments: React.FC<ModuleDocumentsProps> = ({ moduleData }) => {
    return (
        <>
          <Header as="h3">Documents</Header>
          <div style={{ marginBottom: '1.5rem' }}>
            <div style={{ fontSize: '1.1rem', marginBottom: '0.7rem' }}><strong>Module Info:</strong></div>
            <div>
              {moduleData.moduleInfoUrl ? (
                <Button
                  as="a"
                  href={moduleData.moduleInfoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="blue"
                  size="medium"
                >
                  Download
                </Button>
              ) : (
                <Message info size="small" compact>
                  Coming Soon
                </Message>
              )}
            </div>
          </div>
          <div>
            <div style={{ fontSize: '1.1rem', marginBottom: '0.7rem' }}><strong>Question Bank:</strong></div>
            <div>
              {moduleData.questionBankUrl ? (
                <Button
                  as="a"
                  href={moduleData.questionBankUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="blue"
                  size="medium"
                >
                  Download
                </Button>
              ) : (
                <Message info size="small" compact>
                  Coming Soon
                </Message>
              )}
            </div>
          </div>
        </>
      );
};

export default ModuleDocuments;