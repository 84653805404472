import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Header, 
  Table, 
  Button, 
  Checkbox, 
  Message, 
  Loader, 
  Grid, 
  Form, 
  Icon 
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { 
  collection, 
  query, 
  getDocs, 
  where, 
  doc, 
  setDoc, 
  getDoc, 
  Timestamp 
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/config';
import "react-datepicker/dist/react-datepicker.css";

interface Student {
  id: string;
  firstName: string;
  lastName: string;
  initiatedName?: string;
  role: string;
  isActive: boolean;
}

interface AttendanceRecord {
  date: Timestamp;
  attendees: Record<string, boolean>; // Keyed by studentId: true for present, false for absent
  updatedBy: string;
  updatedAt: Timestamp;
}

// Add this utility function at the top level, after the interfaces
const capitalizeWords = (str: string) => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const StudentAttendance: React.FC = () => {
  const [user] = useAuthState(auth);
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [attendees, setAttendees] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState<{ type: 'success' | 'error', content: string } | null>(null);
  const [lastUpdate, setLastUpdate] = useState<{ by: string; at: Date } | null>(null);
  const [updaterDetails, setUpdaterDetails] = useState<{
    initiatedName?: string;
    firstName?: string;
    lastName?: string;
  } | null>(null);

  useEffect(() => {
    fetchStudents();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      fetchAttendance(selectedDate);
    }
  }, [selectedDate]);

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const studentsQuery = query(
        collection(db, 'users'),
        where('role', '==', 'student')       
      );
      
      const snapshot = await getDocs(studentsQuery);
      
      if (snapshot.empty) {
        console.log('No students found');
        setMessage({ type: 'error', content: 'No students found in the database' });
        setStudents([]);
        return;
      }

      const studentsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      } as Student));

      console.log('Fetched students:', studentsList); // Debug log
      
      // Sort students by initiated name if available, otherwise by first name + last name
      const sortedStudents = studentsList.sort((a, b) => {
        const nameA = a.initiatedName || `${a.firstName} ${a.lastName}`;
        const nameB = b.initiatedName || `${b.firstName} ${b.lastName}`;
        return nameA.localeCompare(nameB);
      });

      setStudents(sortedStudents);
      // Clear any existing error messages if students are found
      setMessage(null);
      
    } catch (error) {
      console.error('Error fetching students:', error);
      setMessage({ 
        type: 'error', 
        content: 'Failed to fetch students. Please try refreshing the page.' 
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAttendance = async (date: Date) => {
    const dateString = date.toISOString().split('T')[0];
    try {
      const attendanceDocRef = doc(db, 'attendance', dateString);
      const attendanceDocSnap = await getDoc(attendanceDocRef);
      if (attendanceDocSnap.exists()) {
        const data = attendanceDocSnap.data() as AttendanceRecord;
        setAttendees(data.attendees);
        setLastUpdate({
          by: data.updatedBy,
          at: data.updatedAt.toDate()
        });
        
        // Fetch updater details
        const updaterDoc = await getDoc(doc(db, 'users', data.updatedBy));
        if (updaterDoc.exists()) {
          const updaterData = updaterDoc.data();
          setUpdaterDetails({
            initiatedName: updaterData.initiatedName,
            firstName: updaterData.firstName,
            lastName: updaterData.lastName
          });
        }
      } else {
        // Initialize attendees with all false (absent) if no record exists
        const initialAttendees: Record<string, boolean> = {};
        students.forEach(student => {
          initialAttendees[student.id] = false;
        });
        setAttendees(initialAttendees);
        setLastUpdate(null);
        setUpdaterDetails(null);
      }
    } catch (error) {
      console.error('Error fetching attendance:', error);
      setMessage({ type: 'error', content: 'Failed to fetch attendance record' });
    }
  };

  const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
    setAttendees(prev => ({
      ...prev,
      [studentId]: isPresent
    }));
  };

  const handleSaveAttendance = async () => {
    if (!user) return;

    setSaving(true);
    setMessage(null);
    const dateString = selectedDate.toISOString().split('T')[0];

    try {
      const attendanceDocRef = doc(db, 'attendance', dateString);
      
      // Create attendance record for all students, whether present or absent
      const completeAttendees: Record<string, boolean> = {};
      students.forEach(student => {
        completeAttendees[student.id] = attendees[student.id] || false;
      });

      const attendanceData: AttendanceRecord = {
        date: Timestamp.fromDate(selectedDate),
        attendees: completeAttendees,  // Use the complete attendance record
        updatedBy: user.uid,
        updatedAt: Timestamp.fromDate(new Date())
      };

      await setDoc(attendanceDocRef, attendanceData, { merge: true });

      setMessage({ type: 'success', content: 'Attendance saved successfully' });
      setLastUpdate({
        by: attendanceData.updatedBy,
        at: attendanceData.updatedAt.toDate()
      });

      // Fetch and set updater details
      const updaterDoc = await getDoc(doc(db, 'users', user.uid));
      if (updaterDoc.exists()) {
        const updaterData = updaterDoc.data();
        setUpdaterDetails({
          initiatedName: updaterData.initiatedName,
          firstName: updaterData.firstName,
          lastName: updaterData.lastName
        });
      }
    } catch (error) {
      console.error('Error saving attendance:', error);
      setMessage({ type: 'error', content: 'Failed to save attendance' });
    } finally {
      setSaving(false);
    }
  };

  // Add custom styles for the date picker
  const customDatePickerStyle = {
    input: {
      padding: '9px 14px',
      borderRadius: '4px',
      border: '1px solid rgba(34, 36, 38, 0.15)',
      width: '100%',
      fontSize: '1em'
    }
  };

  if (loading) return <Loader active>Loading students...</Loader>;

  return (
    <Container style={{ padding: '2em 0' }}>
      <Header as="h2" style={{ marginBottom: '2em' }}>Student Attendance</Header>
      
      <Grid stackable style={{ marginBottom: '2em' }}>
        <Grid.Row>
          <Grid.Column width={6}>
            <Form>
              <Form.Field>
                <label>Select Date</label>
                <div style={customDatePickerStyle.input}>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date: Date | null) => date && setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    placeholderText="Select Date"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isClearable
                    className="date-picker-input"
                  />
                </div>
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column width={10}>
            {lastUpdate && (
              <Message info icon>
                <Icon name='info circle' />
                <Message.Content>
                  <Message.Header>Last Update</Message.Header>
                  Updated by {updaterDetails?.initiatedName 
                    ? capitalizeWords(updaterDetails.initiatedName)
                    : updaterDetails?.firstName && updaterDetails?.lastName 
                      ? capitalizeWords(`${updaterDetails.firstName} ${updaterDetails.lastName}`)
                      : 'Unknown'} at {lastUpdate.at.toLocaleString()}
                </Message.Content>
              </Message>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {message && (
        <Message
          positive={message.type === 'success'}
          negative={message.type === 'error'}
          icon
        >
          <Icon name={message.type === 'success' ? 'check circle' : 'times circle'} />
          <Message.Content>{message.content}</Message.Content>
        </Message>
      )}

      <Table celled compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell width={3} textAlign="center">Present</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {students.map(student => (
            <Table.Row key={student.id}>
              <Table.Cell>
                {student.initiatedName 
                  ? capitalizeWords(student.initiatedName)
                  : capitalizeWords(`${student.firstName} ${student.lastName}`)}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Checkbox
                  toggle
                  checked={attendees[student.id] || false}
                  onChange={(_, data) => handleAttendanceChange(student.id, !!data.checked)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Button
        primary
        size="large"
        onClick={handleSaveAttendance}
        loading={saving}
        disabled={saving}
        style={{ marginTop: '2em' }}
      >
        <Icon name="save" />
        Save Attendance
      </Button>
    </Container>
  );
};

export default StudentAttendance;
