import React, { useState, useEffect } from 'react';
import { Table, Button, Message, Segment, Header } from 'semantic-ui-react';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  initiatedName?: string;
  role: string;
}

const AddUsers: React.FC<{ moduleId: string }> = ({ moduleId }) => {
  const [students, setStudents] = useState<User[]>([]);
  const [graders, setGraders] = useState<User[]>([]);
  const [moduleUsers, setModuleUsers] = useState<string[]>([]);
  const [message, setMessage] = useState<{ type: 'success' | 'error', content: string } | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const studentsQuery = query(collection(db, 'users'), where('role', '==', 'student'));
      const gradersQuery = query(collection(db, 'users'), where('role', '==', 'grader'));
      const moduleDoc = await getDocs(query(collection(db, 'modules'), where('moduleId', '==', moduleId)));

      const [studentsSnapshot, gradersSnapshot] = await Promise.all([
        getDocs(studentsQuery),
        getDocs(gradersQuery)
      ]);

      setStudents(studentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as User)));
      setGraders(gradersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as User)));

      if (!moduleDoc.empty) {
        setModuleUsers(moduleDoc.docs[0].data().users || []);
      }
    };

    fetchUsers();
  }, [moduleId]);

  const handleAddUser = async (userId: string) => {
    try {
      await updateDoc(doc(db, 'modules', moduleId), {
        users: arrayUnion(userId)
      });
      setModuleUsers(prev => [...prev, userId]);
      setMessage({ type: 'success', content: 'User added successfully' });
    } catch (error) {
      console.error('Error adding user:', error);
      setMessage({ type: 'error', content: 'Failed to add user' });
    }
  };

  const handleRemoveUser = async (userId: string) => {
    try {
      await updateDoc(doc(db, 'modules', moduleId), {
        users: arrayRemove(userId)
      });
      setModuleUsers(prev => prev.filter(id => id !== userId));
      setMessage({ type: 'success', content: 'User removed successfully' });
    } catch (error) {
      console.error('Error removing user:', error);
      setMessage({ type: 'error', content: 'Failed to remove user' });
    }
  };

  const renderUserTable = (users: User[], title: string) => (
    <Segment>
      <Header as="h3">{title}</Header>
      <Table celled fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={12}>Name</Table.HeaderCell>
            <Table.HeaderCell width={4}>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.map(user => (
            <Table.Row key={user.id}>
              <Table.Cell>
                {user.firstName} {user.lastName}
                {user.initiatedName && ` (${user.initiatedName})`}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {moduleUsers.includes(user.id) ? (
                  <Button negative onClick={() => handleRemoveUser(user.id)}>Remove</Button>
                ) : (
                  <Button positive onClick={() => handleAddUser(user.id)}>Add</Button>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );

  return (
    <div>
      <Header as="h2">Add Users to Module</Header>
      {message && (
        <Message
          positive={message.type === 'success'}
          negative={message.type === 'error'}
          content={message.content}
        />
      )}
      {renderUserTable(students, 'Students')}
      {renderUserTable(graders, 'Graders')}
    </div>
  );
};

export default AddUsers;