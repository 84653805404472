import React, { useState, useEffect } from 'react';
import { Table, Dropdown, DropdownItemProps, Button, Message } from 'semantic-ui-react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';

interface User {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  initiatedName?: string;
  spiritualMaster?: string;
  chanting16Rounds: boolean;
  following4Principles: boolean;
  role: 'student' | 'grader' | 'teacher' | 'admin' | 'coordinator';
  showInTeamPage?: boolean;
}

const UsersTable: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [roleFilter, setRoleFilter] = useState<string | null>(null);
  const [message, setMessage] = useState<{ type: 'positive' | 'negative', content: string } | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const userSnapshot = await getDocs(usersCollection);
      const userList = userSnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() } as User));
      setUsers(userList);
      setFilteredUsers(userList);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (roleFilter) {
      setFilteredUsers(users.filter(user => user.role === roleFilter));
    } else {
      setFilteredUsers(users);
    }
  }, [roleFilter, users]);

  const roleOptions: DropdownItemProps[] = [
    { key: 'all', text: 'All Roles', value: 'all' },
    { key: 'student', text: 'Student', value: 'student' },
    { key: 'grader', text: 'Grader', value: 'grader' },
    { key: 'teacher', text: 'Teacher', value: 'teacher' },
    { key: 'admin', text: 'Admin', value: 'admin' },
    { key: 'coordinator', text: 'Coordinator', value: 'coordinator' },
  ];

  const toggleShowInTeamPage = async (user: User) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      const newShowInTeamPage = !user.showInTeamPage;
      await updateDoc(userRef, { showInTeamPage: newShowInTeamPage });
      
      // Update local state
      const updatedUsers = users.map(u => 
        u.uid === user.uid ? { ...u, showInTeamPage: newShowInTeamPage } : u
      );
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);

      // Show success message
      setMessage({ type: 'positive', content: `User ${user.firstName} ${user.lastName} ${newShowInTeamPage ? 'will now' : 'will no longer'} be shown on the team page.` });
      
      // Clear message after 3 seconds
      setTimeout(() => setMessage(null), 3000);
    } catch (error) {
      console.error('Error updating user:', error);
      setMessage({ type: 'negative', content: 'Failed to update user. Please try again.' });
      setTimeout(() => setMessage(null), 3000);
    }
  };

  return (
    <div>
      {message && (
        <Message
          positive={message.type === 'positive'}
          negative={message.type === 'negative'}
          content={message.content}
        />
      )}
      <Dropdown
        selection
        options={roleOptions}
        value={roleFilter || 'all'}
        onChange={(_, data) => setRoleFilter(data.value as string)}
        placeholder="Filter by role"
        style={{ marginBottom: '1rem' }}
      />
      <div style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '70vh' }}>
        <Table celled style={{ minWidth: '1000px' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>User Id</Table.HeaderCell>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Mobile Number</Table.HeaderCell>
              <Table.HeaderCell>Initiated Name</Table.HeaderCell>
              <Table.HeaderCell>Spiritual Master</Table.HeaderCell>
              <Table.HeaderCell>Chanting 16 Rounds</Table.HeaderCell>
              <Table.HeaderCell>Following 4 Principles</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell>Show In Team Page</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredUsers.map((user, index) => (
              <Table.Row key={user.uid}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{user.uid}</Table.Cell>
                <Table.Cell>{user.firstName}</Table.Cell>
                <Table.Cell>{user.lastName}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.mobileNumber}</Table.Cell>
                <Table.Cell>{user.initiatedName || '-'}</Table.Cell>
                <Table.Cell>{user.spiritualMaster || '-'}</Table.Cell>
                <Table.Cell>{user.chanting16Rounds ? 'Yes' : 'No'}</Table.Cell>
                <Table.Cell>{user.following4Principles ? 'Yes' : 'No'}</Table.Cell>
                <Table.Cell>{user.role}</Table.Cell>
                <Table.Cell>
                  {['admin', 'teacher', 'grader', 'coordinator'].includes(user.role) && (
                    <Button
                      toggle
                      active={user.showInTeamPage}
                      onClick={() => toggleShowInTeamPage(user)}
                    >
                      {user.showInTeamPage ? 'Yes' : 'No'}
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default UsersTable;