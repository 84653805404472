import React, { useState, useEffect } from 'react';
import { Container, Grid, Header, Image, Card, Button, Label, List } from 'semantic-ui-react';
import { collection, getDocs, limit, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/config'; // Make sure this path is correct
import logo from '../assets/logo.jpg';

interface Course {
  id: string;
  name: string;
  description: string;
  courseFee: number;
  imageUrl: string;
  startDate: Date;
  endDate: Date;
  difficulty: string;
}

const Home: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    const fetchCourses = async () => {
      const coursesQuery = query(
        collection(db, 'courses'),
        orderBy('createdAt', 'desc'),
        limit(3)
      );
      const querySnapshot = await getDocs(coursesQuery);
      const fetchedCourses = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        startDate: doc.data().startDate?.toDate(),
        endDate: doc.data().endDate?.toDate(),
      } as Course));
      setCourses(fetchedCourses);
    };

    fetchCourses();
  }, []);

  return (
    <Container>
      {/* Hero Section */}
      <Grid style={{ marginTop: '2em', marginBottom: '2em' }}>
        <Grid.Row>
          <Grid.Column width={8} verticalAlign='middle'>
            <Header as='h1' textAlign='center'>
              Welcome to Bhaktivedanta Vedic Academy's
              <Header.Subheader 
                as='h2' 
                style={{ 
                  fontSize: '1.5em', 
                  color: '#4183c4', 
                  marginTop: '0.5em' 
                }}
              >
                Bhakti Sastri Course
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <Image src={logo} size='large' floated='right' />
          </Grid.Column>
        </Grid.Row>
      </Grid>      

      {/* Course Description Section */}
      <Grid style={{ marginTop: '3em', marginBottom: '3em' }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as='h2' textAlign='center' color='blue'>
              About Bhakti Sastri Course
            </Header>
            <Card fluid>
              <Card.Content>
                <Card.Description>
                  <p>
                    The Bhakti Sastri course is a comprehensive study program offered by ISKCON's Bhaktivedanta Vedic Academy, 
                    designed to provide a deep understanding of Vedic philosophy and spiritual practices. This systematic study 
                    of Srila Prabhupada's books helps students develop both spiritually and intellectually.
                  </p>
                  
                  <Header as='h3' color='blue'>Course Goals</Header>
                  <Grid columns={2} stackable>
                    <Grid.Column>
                      <List bulleted>
                        <List.Item>Foster meaningful friendships with fellow spiritual aspirants</List.Item>
                        <List.Item>Cultivate regular reading habits of Srila Prabhupada's books</List.Item>
                        <List.Item>Master foundational philosophical concepts</List.Item>
                        <List.Item>Develop exemplary character through Vedic wisdom</List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column>
                      <List bulleted>
                        <List.Item>Deepen faith in Krishna through systematic study</List.Item>
                        <List.Item>Strengthen the practice of Bhakti through knowledge</List.Item>
                        <List.Item>Develop a service attitude</List.Item>
                        <List.Item>Apply philosophical teachings in daily life</List.Item>
                      </List>
                    </Grid.Column>
                  </Grid>

                  <Header as='h3' color='blue' style={{ marginTop: '2em' }}>Course Modules</Header>
                  <Card.Group itemsPerRow={2} stackable>
                    <Card>
                      <Card.Content>
                        <Card.Header>Bhagavad Gita</Card.Header>
                        <Card.Description>
                          In-depth study of Krishna's divine instructions to Arjuna, covering topics of karma, 
                          dharma, the science of self-realization and devotional service.
                        </Card.Description>
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Sri Isopanisad</Card.Header>
                        <Card.Description>
                          Essential Vedic knowledge exploring the nature of the Supreme, the living entities, 
                          and the material world.
                        </Card.Description>
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Nectar of Devotion</Card.Header>
                        <Card.Description>
                          Practical guide to devotional service, explaining the science of Bhakti-yoga and its application.
                        </Card.Description>
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Sri Upadesamrta</Card.Header>
                        <Card.Description>
                          Nectar of Instruction providing essential guidance for spiritual advancement and 
                          devotional practice.
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Card.Group>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Home;