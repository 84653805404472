import { atom } from 'recoil';

interface UserState {
  uid: string | null;
  email: string | null;
  role: 'admin' | 'user' | 'teacher' | 'grader' | 'student' |  'staff' | null;
}

export const userState = atom<UserState>({
  key: 'userState',
  default: {
    uid: null,
    email: null,
    role: null,
  },
});