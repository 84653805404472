import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { collection, query, where, getDocs, doc, getDoc, limit } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase/config';
import Layout from './Layout/Layout';
import AppRoutes from './routes/AppRoutes';
import CreateAdminUser from './Authentication/CreateAdminUser';
import 'semantic-ui-css/semantic.min.css';
import { RecoilRoot, useSetRecoilState } from 'recoil';
import { userState } from './recoil/authAtom';

function AppContent() {
  const [adminExists, setAdminExists] = useState<boolean | null>(null);
  const setUser = useSetRecoilState(userState);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkAdminExists = async () => {
      try {
        const q = query(collection(db, 'users'), where('role', '==', 'admin'));
        const querySnapshot = await getDocs(q);
        setAdminExists(!querySnapshot.empty);
      } catch (error) {
        console.error("Error checking admin existence:", error);
        setError("Failed to check if admin exists. Please check your database permissions.");
        setAdminExists(false);
      }
    };

    checkAdminExists();

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userDocRef = doc(db, 'users', firebaseUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            role: userData.role || null,
          });
        } else {
          console.log("No such document!");
          setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            role: null,
          });
        }
      } else {
        setUser({
          uid: null,
          email: null,
          role: null,
        });
      }
    });
  
    return () => unsubscribe();
  }, [setUser]);

  if (error) {
    return <div>Error: {error}</div>;
  }
  
  if (adminExists === null) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Layout>
        <Container style={{ marginTop: '5em' }}>
          {!adminExists ? (
            <CreateAdminUser />
          ) : (
            <AppRoutes />
          )}
        </Container>
      </Layout>
    </Router>
  );
}

function App() {
  return (
    <RecoilRoot>
      <AppContent />
    </RecoilRoot>
  );
}

export default App;