import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import EditProfileNonStudent from './EditProfileNonStudent';
import EditProfileStudent from './EditProfileStudent';

const EditProfile: React.FC = () => {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
        setLoading(false);
      }
    };
    fetchUserData();
  }, [user]);

  if (!user) {
    return <div>Please log in to edit your profile.</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (userData?.role === 'student') {
    return <EditProfileStudent />;
  } else {
    return <EditProfileNonStudent />;
  }
};

export default EditProfile;