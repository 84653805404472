export interface MixedUser {
    firstName: string;
    lastName: string;
    initiatedName: string;
    spiritualMaster: string;
    email: string;
    phoneNumber: number;
    role: 'teacher' | 'grader' | 'admin' | 'coordinator' | 'student';
  }

  export const mixedUsersData: MixedUser[] =[
    {
      firstName: "Rashim",
      lastName: "Joshi",
      initiatedName: "Radha Jivani Devi Dasi",
      spiritualMaster: "HH Radhanath swami Maharaj",
      email: "radhajivani.rns@gmail.com",
      phoneNumber: 4696623313,
      role: "grader"
    },
    {
      firstName: "Noa",
      lastName: "Neighbors",
      initiatedName: "Nrtya Kisori Dasi",
      spiritualMaster: "H.H. Tamal Krishna Goswami",
      email: "Nrtya.Kisori.TKG@gmail.com",
      phoneNumber: 2148864763,
      role: "teacher"
    },
    {
      firstName: "Shekhar",
      lastName: "Nikam",
      initiatedName: "Syama Kesava dasa",
      spiritualMaster: "HH Radhanath swami Maharaj",
      email: "shekhar.nikam@gmail.com",
      phoneNumber: 8578915938,
      role: "grader"
    },
    {
      firstName: "Mrudangi",
      lastName: "Bhatt",
      initiatedName: "Hemangi Dasi",
      spiritualMaster: "H.H. Giriraj Swami",
      email: "Hemangigrs@gmail.com",
      phoneNumber: 2142840920,
      role: "admin"
    },
    {
      firstName: "Shilpa",
      lastName: "Lombar",
      initiatedName: "Shiromani Radha Devi Dasi",
      spiritualMaster: "HH Radhanath swami Maharaj",
      email: "Tihielombar@gmail.com",
      phoneNumber: 3092758557,
      role: "coordinator"
    },
    {
      firstName: "Satya",
      lastName: "Gundavarapu",
      initiatedName: "Sakhya Prem Dasa",
      spiritualMaster: "HH Lokanath Swami Maharaj",
      email: "Ramaniashu@gmail.com",
      phoneNumber: 6465777411,
      role: "teacher"
    },
    {
      firstName: "Vidyavathi",
      lastName: "Gundavarapu",
      initiatedName: "Vagisha Devi Dasi",
      spiritualMaster: "HH Lokanath Swami Maharaj",
      email: "Vijjug19@gmail.com",
      phoneNumber: 9494511560,
      role: "grader"
    },
    {
      firstName: "Arti",
      lastName: "Shinde",
      initiatedName: "Rasakeli devi dasi",
      spiritualMaster: "HH Radhanath swami Maharaj",
      email: "Artirns@gmail.com",
      phoneNumber: 2144543949,
      role: "grader"
    },
    {
      firstName: "Hrishikesh",
      lastName: "Shinde",
      initiatedName: "Rasaraj das",
      spiritualMaster: "HH Radhanath swami Maharaj",
      email: "rasarajdas@gmail.com",
      phoneNumber: 2146422488,
      role: "teacher"
    },
    {
      firstName: "Rasalila",
      lastName: "Shinde",
      initiatedName: "Rasika Rani Devi Dasi",
      spiritualMaster: "HH Radhanath Swami",
      email: "rasalilarns@gmail.com",
      phoneNumber: 4692585111,
      role: "student"
    },
    {
      firstName: "Sanskriti",
      lastName: "Dwivedi",
      initiatedName: "",
      spiritualMaster: "",
      email: "sanskriti.dwivedi108@gmail.com",
      phoneNumber: 4694105317,
      role: "student"
    },
    {
      firstName: "pankaj",
      lastName: "dwivedi",
      initiatedName: "prem chandra das",
      spiritualMaster: "HH Radhanath swami Maharaj",
      email: "pdwi99@yahoo.com",
      phoneNumber: 6023693019,
      role: "teacher"
    }
  ]