import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/authAtom';

interface TeacherAdminStaffRouteProps {
  children: React.ReactNode;
}

const TeacherAdminStaffRoute: React.FC<TeacherAdminStaffRouteProps> = ({ children }) => {
  const user = useRecoilValue(userState);

  const allowedRoles = ['teacher', 'admin', 'staff'];
  
  if (!user.role || !allowedRoles.includes(user.role)) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default TeacherAdminStaffRoute;