import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Form as SemanticForm, Message, Container, Header, Grid } from 'semantic-ui-react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase/config';
import { useNavigate, Link } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import { doc, getDoc } from 'firebase/firestore';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: { email: string; password: string }, { setSubmitting }: any) => {
    setErrorMessage(null);
    setIsSubmitting(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
    const user = userCredential.user;
    
    // Check if it's the user's first login
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    const userData = userDoc.data();
    
    if (userData && userData.isFirstLogin) {
      // Redirect to change password page
      navigate('/change-password', { state: { isFirstLogin: true } });
    } else {
      navigate('/');
    }
    } catch (error) {
      console.error('Error signing in:', error);
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/user-not-found':
            setErrorMessage('No user found with this email. Please check your email or sign up.');
            break;
          case 'auth/wrong-password':
            setErrorMessage('Incorrect password. Please try again.');
            break;
          case 'auth/invalid-email':
            setErrorMessage('Invalid email address. Please enter a valid email.');
            break;
          case 'auth/user-disabled':
            setErrorMessage('This account has been disabled. Please contact support.');
            break;
          case 'auth/too-many-requests':
            setErrorMessage('Too many failed login attempts. Please try again later.');
            break;
          default:
            setErrorMessage('An error occurred during login. Please try again.');
        }
      } else {
        setErrorMessage(error instanceof Error ? error.message : 'An unknown error occurred');
      }
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Header as="h2" color="teal" textAlign="center">
        Log in to your account
      </Header>
      {errorMessage && (
        <Message
          negative
          onDismiss={() => setErrorMessage(null)}
          header="Error"
          content={errorMessage}
        />
      )}
      <Grid centered>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="ui form">
                <SemanticForm.Field required>
                  <label htmlFor="email">Email</label>
                  <Field name="email" type="email" placeholder="Email" />
                  {errors.email && touched.email && (
                    <Message negative content={errors.email} />
                  )}
                </SemanticForm.Field>

                <SemanticForm.Field required>
                  <label htmlFor="password">Password</label>
                  <Field name="password" type="password" placeholder="Password" />
                  {errors.password && touched.password && (
                    <Message negative content={errors.password} />
                  )}
                </SemanticForm.Field>

                <SemanticForm.Field style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button 
                    type="submit" 
                    primary 
                    disabled={isSubmitting} 
                    size="large"
                    style={{ width: '200px' }}
                  >
                    {isSubmitting ? 'Logging in...' : 'Log In'}
                  </Button>
                </SemanticForm.Field>
              </Form>
            )}
          </Formik>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default Login;