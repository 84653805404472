import React from 'react';
import { Menu } from 'semantic-ui-react';

interface StudentVerticalMenuProps {
  activeItem: string;
  onItemClick: (name: string) => void;
}

const StudentVerticalMenu: React.FC<StudentVerticalMenuProps> = ({ activeItem, onItemClick }) => {
  return (
    <Menu vertical>
      <Menu.Item
        name='myCourses'
        active={activeItem === 'myCourses'}
        onClick={() => onItemClick('myCourses')}
      >
        My Courses
      </Menu.Item>
      <Menu.Item
        name='progress'
        active={activeItem === 'progress'}
        onClick={() => onItemClick('progress')}
      >
        My Progress
      </Menu.Item>
      {/* Add more student-specific menu items here */}
    </Menu>
  );
};

export default StudentVerticalMenu;