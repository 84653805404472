import React, { useState, useEffect } from 'react';
import { Form, Button, Message, Image, Input } from 'semantic-ui-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, storage, db } from '../../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, setDoc, doc } from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';

interface ModuleData {
  moduleId: string;
  name: string;
  description: string;
  startDate: Date | null;
  endDate: Date | null;
  isActive: boolean;
  viewAssignments: boolean;
  photoUrl: string | null;
  moduleInfoUrl: string | null;
  questionBankUrl: string | null;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  isDeleted: boolean;
  deletedDate: Date | null;
  deletedBy: string | null;
}

const datePickerStyles = {
  container: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1rem'
  },
  wrapper: {
    position: 'relative' as const,
    zIndex: 100
  },
  input: {
    padding: '0.5rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '200px'
  }
};

const CreateModule: React.FC = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [moduleData, setModuleData] = useState<ModuleData>({
    moduleId: '',
    name: '',
    description: '',
    startDate: null,
    endDate: null,
    isActive: true,
    viewAssignments: false,
    photoUrl: null,
    moduleInfoUrl: null,
    questionBankUrl: null,
    createdBy: '',
    createdDate: new Date(),
    updatedBy: '',
    updatedDate: new Date(),
    isDeleted: false,
    deletedDate: null,
    deletedBy: null,
  });
  const [photo, setPhoto] = useState<File | null>(null);
  const [moduleInfo, setModuleInfo] = useState<File | null>(null);
  const [questionBank, setQuestionBank] = useState<File | null>(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/login');
    }
  }, [user, loading, navigate]);

  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'file'
      );
    });

  const handlePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const resizedImage = await resizeFile(file);
      setPhoto(resizedImage);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<File | null>>) => {
    if (e.target.files && e.target.files.length > 0) {
      setter(e.target.files[0]);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    data?: any
  ) => {
    const { name, value, type } = data || e.target;
    setModuleData(prev => {
      const newData = {
        ...prev,
        [name]: type === 'checkbox' ? data.checked : value
      };
      
      // Set moduleId based on the name
      if (name === 'name') {
        newData.moduleId = value.toLowerCase().replace(/\s+/g, '-');
      }
      
      return newData;
    });
  };

  const handleDateChange = (date: Date | null, field: keyof ModuleData) => {
    setModuleData(prev => ({ ...prev, [field]: date }));
  };

  const uploadFile = async (file: File, path: string) => {
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    setSubmitting(true);

    if (!user) {
      setError('You must be logged in to create a module');
      setSubmitting(false);
      return;
    }

    try {
      let photoUrl = null;
      let moduleInfoUrl = null;
      let questionBankUrl = null;

      if (photo) {
        photoUrl = await uploadFile(photo, `modules/${moduleData.name}/${photo.name}`);
      }
      if (moduleInfo) {
        moduleInfoUrl = await uploadFile(moduleInfo, `modules/${moduleData.name}/moduleInfo/${moduleInfo.name}`);
      }
      if (questionBank) {
        questionBankUrl = await uploadFile(questionBank, `modules/${moduleData.name}/questionBank/${questionBank.name}`);
      }

      const userName = user.displayName || `${user.email}`;
      const moduleDataToSave = {
        ...moduleData,
        photoUrl,
        moduleInfoUrl,
        questionBankUrl,
        createdBy: userName,
        createdDate: new Date(),
        updatedBy: userName,
        updatedDate: new Date(),
      };

      await setDoc(doc(db, 'modules', moduleData.moduleId), moduleDataToSave);

      setSuccess(true);
      setTimeout(() => {
        navigate('/modules');
      }, 3000);
    } catch (err) {
      console.error(err);
      setError('Failed to create module. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h2>Create Module</h2>
      <Form onSubmit={handleSubmit} error={!!error} success={success}>
        <Form.Input
          label="Module Name"
          name="name"
          value={moduleData.name}
          onChange={handleInputChange}
          required
          input={{ type: 'text' }}
        />
        <Form.TextArea
          label="Description"
          name="description"
          value={moduleData.description}
          onChange={handleInputChange}
          required
          rows={4}
        />
        <Form.Field>
          <label>Module Duration</label>
          <div style={datePickerStyles.container}>
            <div style={datePickerStyles.wrapper}>
              <DatePicker
                selected={moduleData.startDate}
                onChange={(date: Date | null) => handleDateChange(date, 'startDate')}
                selectsStart
                startDate={moduleData.startDate || undefined}
                endDate={moduleData.endDate || undefined}
                dateFormat="dd/MM/yyyy"
                placeholderText="Start Date"
                customInput={<input style={datePickerStyles.input} />}
              />
            </div>
            <div style={datePickerStyles.wrapper}>
              <DatePicker
                selected={moduleData.endDate}
                onChange={(date: Date | null) => handleDateChange(date, 'endDate')}
                selectsEnd
                startDate={moduleData.startDate || undefined}
                endDate={moduleData.endDate || undefined}
                dateFormat="dd/MM/yyyy"
                placeholderText="End Date"
                customInput={<input style={datePickerStyles.input} />}
              />
            </div>
          </div>
        </Form.Field>
        <Form.Checkbox
          label="Is Active"
          name="isActive"
          checked={moduleData.isActive}
          onChange={(e, data) => handleInputChange(e as React.ChangeEvent<HTMLInputElement>, data)}
          toggle
        />
        <Form.Checkbox
          label="View Assignments"
          name="viewAssignments"
          checked={moduleData.viewAssignments}
          onChange={(e, data) => handleInputChange(e as React.ChangeEvent<HTMLInputElement>, data)}
          toggle
        />
        <Form.Field>
          <label>Module Photo</label>
          <Input
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
          />
        </Form.Field>
        <Form.Field>
          <label>Module Info Document (Optional)</label>
          <Input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={(e) => handleFileChange(e, setModuleInfo)}
          />
        </Form.Field>
        <Form.Field>
          <label>Question Bank Document (Optional)</label>
          <Input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={(e) => handleFileChange(e, setQuestionBank)}
          />
        </Form.Field>
        <Button type="submit" primary disabled={submitting}>
          {submitting ? 'Creating...' : 'Create Module'}
        </Button>
        <Message error content={error} />
        <Message success content="Module created successfully!" />
      </Form>
    </div>
  );
};

export default CreateModule;