import React, { useState } from 'react';
import { Form, Button, Input, TextArea, Divider, Label, Message } from 'semantic-ui-react';
import { auth, db } from '../../../firebase/config';
import { collection, addDoc, doc, getDoc, Timestamp } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { timeStamp } from 'console';

interface Criteria {
  description: string;
}

interface Question {
  text: string;
  criteria: Criteria[];
}

const OpenBookTest: React.FC<{ moduleId: string; onTestCreated: () => void }> = ({ moduleId, onTestCreated }) => {
  const [testName, setTestName] = useState('');
  const [totalGrade, setTotalGrade] = useState(20);
  const [lastSubmissionDate, setLastSubmissionDate] = useState('');
  const [lastSubmissionTime, setLastSubmissionTime] = useState('');
  const [questions, setQuestions] = useState<Question[]>([
    { text: '', criteria: [{ description: '' }] },
    { text: '', criteria: [{ description: '' }] },
  ]);
  const [successMessage, setSuccessMessage] = useState('');

  const handleQuestionChange = (index: number, value: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].text = value;
    setQuestions(updatedQuestions);
  };

  const handleCriteriaChange = (questionIndex: number, criteriaIndex: number, value: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].criteria[criteriaIndex].description = value;
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { text: '', criteria: [{ description: '' }] }]);
  };

  const removeQuestion = (index: number) => {
    if (questions.length > 2) {
      const updatedQuestions = questions.filter((_, i) => i !== index);
      setQuestions(updatedQuestions);
    }
  };

  const addCriteria = (questionIndex: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].criteria.push({ description: '' });
    setQuestions(updatedQuestions);
  };

  const removeCriteria = (questionIndex: number, criteriaIndex: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].criteria.splice(criteriaIndex, 1);
    setQuestions(updatedQuestions);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser!.uid));
      const userData = userDoc.data();
      const createdBy = userData?.initiatedName || `${userData?.firstName} ${userData?.lastName}`;

      const lastSubmissionTimestamp = Timestamp.fromDate(new Date(`${lastSubmissionDate}T${lastSubmissionTime}:00`));
  
      const testData = {
        moduleId,
        testName,
        totalGrade,
        lastSubmissionDate: lastSubmissionTimestamp,
        questions: questions.map(question => ({
          ...question,
          criteria: question.criteria.map(criteria => ({
            ...criteria,
            grade: calculatePointsPerCriteria(questions.indexOf(question))
          }))
        })),
        type: 'openBook',
        createdAt: new Date(),
        createdBy,
      };
  
      const moduleRef = doc(db, 'modules', moduleId);
      const testsCollectionRef = collection(moduleRef, 'tests');
      await addDoc(testsCollectionRef, testData);
  
      setSuccessMessage('Open Book Test created successfully!');
      onTestCreated();
    } catch (error) {
      console.error('Error creating test:', error);
      alert('Failed to create test. Please try again.');
    }    
  };
  
  const calculatePointsPerCriteria = (questionIndex: number) => {
    const criteriaCount = questions[questionIndex].criteria.length;
    return criteriaCount > 0 ? totalGrade / criteriaCount : 0;
  };

  return (
    <Form onSubmit={handleSubmit}>
      {successMessage && <Message positive>{successMessage}</Message>}
      <Form.Field>
        <label>Test Name</label>
        <Input 
          value={testName} 
          onChange={(e) => setTestName(e.target.value)} 
          placeholder="Enter test name" 
          required 
        />
      </Form.Field>
      <Form.Field>
        <label>Total Grade</label>
        <Input 
          type="number" 
          value={totalGrade} 
          onChange={(e) => setTotalGrade(Number(e.target.value))} 
          min={1} 
          required 
        />
      </Form.Field>
      <Form.Field>
      <label>Last Submission Date and Time</label>
        <Input 
          type="date" 
          value={lastSubmissionDate} 
          onChange={(e) => setLastSubmissionDate(e.target.value)} 
          required 
        />
        <Input 
          type="time" 
          value={lastSubmissionTime} 
          onChange={(e) => setLastSubmissionTime(e.target.value)} 
          required 
          style={{ marginTop: '0.5rem' }}
        />
      </Form.Field>
      {questions.map((question, questionIndex) => (
        <div key={questionIndex}>
          <Divider />
          <h3>Question Option {questionIndex + 1}</h3>
          <Form.Field>
            <label>Question Text</label>
            {/* <TextArea
              value={question.text}
              onChange={(e) => handleQuestionChange(questionIndex, e.target.value)}
              placeholder="Enter question text"
              required
            /> */}
            <ReactQuill
              value={question.text}
              onChange={(value) => handleQuestionChange(questionIndex, value)} // Update the handler to accept rich text
              placeholder="Enter question text"
            />
          </Form.Field>
          <h4>Criteria</h4>
          {question.criteria.map((criteria, criteriaIndex) => (
            <Form.Field key={criteriaIndex}>
              <Input
                value={criteria.description}
                onChange={(e) => handleCriteriaChange(questionIndex, criteriaIndex, e.target.value)}
                placeholder="Enter criteria description"
                required
                label={{ basic: true, content: `${calculatePointsPerCriteria(questionIndex).toFixed(2)} points` }}
                labelPosition="right"
              />
              <Button type="button" color="red" onClick={() => removeCriteria(questionIndex, criteriaIndex)}>Remove Criteria</Button>
            </Form.Field>
          ))}
          <Button type="button" color="green" onClick={() => addCriteria(questionIndex)}>Add Criteria</Button>
          {questions.length > 2 && (
            <Button type="button" color="red" onClick={() => removeQuestion(questionIndex)}>Remove Question Option</Button>
          )}
        </div>
      ))}
      <Button style={{ marginTop: '1rem' }} type="button" color="green" onClick={addQuestion}>Add Question Option</Button>
      <Divider />      
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <Button type="submit" color="green">Create Open Book Test</Button>
      </div>
    </Form>
  );
};

export default OpenBookTest;