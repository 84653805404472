import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';

const PageTitle: React.FC = () => {
  const location = useLocation();
  
  // Function to generate title based on current path
  const getTitle = () => {
    const path = location.pathname.split('/')[1];
    return path.charAt(0).toUpperCase() + path.slice(1) || 'Home';
  };

  return (
    <div style={{ 
      backgroundColor: '#5CCCFF', 
      padding: '5rem 0', 
      marginBottom: '2rem',
      marginTop: '120px', // Adjust this value based on your Navigation bar height
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw'
    }}>
      <Container>
        <Header as='h1' style={{ color: 'white' }}>{getTitle()}</Header>
      </Container>
    </div>
  );
};

export default PageTitle;